$promociones-box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);
$promociones-botton-bg: #fff;
$promociones-modal-bg: #fff;
$promociones-card-bg: #fff;
$promociones-primary: #F06031;
$promociones-secondary: #903392;
$promociones-alternative: #FAFAFA;
$promociones-hover: #ff6d3d;
$promociones-font-color-alternative: #fff;
$z-index-filter: 200;

.promociones {
	padding-bottom: 50px;

	&__title {
		padding: 100px 20px 120px 20px;
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: $promociones-font-color-alternative; }

	// -----------------> default

	&__default {
		margin-top: 125px;
		@include desktop {
			// padding: 100px 0 200px 0
			margin-bottom: 50px; }
		&__container {
			position: relative;
			background: $promociones-primary;
			height: 120px;
			width: 120px;
			border-radius: 50%;
			display: flex;
			padding-left: 36px;
			align-items: center;
			margin: 0 auto; }

		&__paragraph {
			text-align: center;
			padding-top: 30px;
			color: $promociones-primary; } }

	// -----------------> contenido

	&__container {
		margin-top: -50px;
		padding: 0 30px;
		&__selecter {
			display: flex;
			justify-content: space-evenly;
			padding-bottom: 20px;
			max-width: 1200px;
			margin: 0 auto;

			@include desktop {
				justify-content: flex-end; }

			&__filter {
				flex-basis: calc( 50% - 10px );
				background: $promociones-botton-bg;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 22px;
				padding: 10px 20px;
				position: relative;

				@include desktop {
					max-width: 195px; }

				&__filter-on-point {
					height: 14px;
					width: 14px;
					background: $promociones-secondary;
					border-radius: 50%;
					margin-right: 10px; }
				&__name {
					font-size: .9rem; }

				&--filtro {
					@include desktop {
						display: none; } }

				&--tiendas {
					display: none;
					@include desktop {
						display: block;
						padding-left: 20px;
						width: 100%;
						max-width: 195px;
						font-size: .9rem; } }
				&--categorias {
					display: none;
					@include desktop {
						display: block;
						padding-left: 20px;
						width: 100%;
						max-width: 195px;
						font-size: .9rem; } }
				&--ordenar {
					display: none;

					@include desktop {
						display: block;
						padding-left: 20px;
						width: 100%;
						max-width: 195px;
						font-size: .9rem; } }

				&--ordenar-mobile {
					@include desktop {
						display: none; } } } }

		&__container-promo {
			display: grid;
			grid-gap: 20px;
			justify-items: center;
			padding: 0 0 20px 0;
			max-width: 1200px;
			margin: 0 auto;

			@include tablet {
				grid-template-columns: repeat(2, 1fr); }
			@include desktop {
				grid-template-columns: repeat(3, 1fr);
				grid-gap: 40px; }
			@include fullhd {
				grid-template-columns: repeat(4, 1fr); } }

		&__show-more {
			max-width: 315px;
			margin: 30px auto 60px auto;
			button {
				background: $promociones-primary !important;
				&:hover {
					background: $promociones-hover !important; } } }
		&__show-less {
			display: none;
			max-width: 315px;
			margin: 30px auto 60px auto;
			button {
				background: $promociones-primary !important;
				&:hover {
					background: $promociones-hover !important; } } } }

	// -----------------> modal filter

	.promociones-filter {
		position: fixed;
		overflow: scroll;
		top: 0;
		left: 0;
		height: 100%;
		width: 100vw;
		background: $promociones-modal-bg;
		z-index: $z-index-filter;
		padding: 80px 30px 30px 30px;

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&__title {
				font-size: 1.4rem; }

			&__close {
				height: 34px;
				width: 34px;
				background: $promociones-alternative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%; } }

		&__select {
			position: relative;
			margin-top: 30px;
			&__title {
				margin: 10px 0; }
			&__chevron {
				position: absolute;
				top: 0;
				right: 0;
				padding: 5px;
				transition: ease all .3s; }
			&__list {
				display: none;
				background: $promociones-alternative;
				margin: 0 -30px;

				li {
					padding: 5px 10px 5px 60px;
					position: relative;
					cursor: pointer; } } }
		&__clean {
			margin-top: 100px;
			padding: 30px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: 1px solid $promociones-alternative;
			color: $promociones-secondary;
			font-weight: bold; } }

	// -----------------> modal order

	.promociones-order {
		position: fixed;
		overflow: scroll;
		top: 0;
		left: 0;
		height: 100%;
		width: 100vw;
		background: $promociones-modal-bg;
		z-index: $z-index-filter;
		padding: 80px 30px 30px 30px;

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&__title {
				font-size: 1.4rem; }
			&__close {
				height: 34px;
				width: 34px;
				background: $promociones-alternative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%; } }

		&__list {
			margin: 30px -30px;

			li {
				padding: 5px 10px 5px 60px;
				position: relative;
				cursor: pointer; } } } }


.filter-on {
	color: $promociones-secondary;
	&::before {
		content: "";
		position: absolute;
		background: url(../images/promociones/check-icon.png) no-repeat center center;
		background-size: cover;
		top: 50%;
		left: 0;
		margin-left: 30px;
		height: 17px;
		width: 17px;
		transform: translateY(-50%);
		fill: red; } }

.rotate-toggle {
	transform: rotate(-180deg);
	color: $promociones-secondary; }
.color-toggle {
	color: $promociones-secondary; }

.show-more {
	background: red!important; }
