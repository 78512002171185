$servicios-card-bg: #fff;
$servicios-font-color: #333333;
$servicios-font-color-secondary: #fff;
$servicios-primary: #FF9733;
$servicios-hover: #ffa652;
$servicios-superposition: rgba(0,0,0,0.5);
$servicios-box-shadow: 1px 1px 10px 0px rgba(112,112,112,0.2);

// ............................................ { servicios } #

// Events list styles
.servicios {
	padding-bottom: 250px;

	&__padding {
		padding: 0 30px;
		max-width: 1200px;
		width: 100%;
		margin: 0 auto; }

	&__title {
		padding: 100px 20px 200px 20px;
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: $nosotros-font-color-neutral;

		@include desktop {
			padding: 80px 20px; } }

	&__cards {
		padding: 30px 0;

		&__title {
			font-size: 1.6rem;
			color: $servicios-primary;
			padding-bottom: 20px;
			text-transform: uppercase; }

		&__container {
			display: grid;
			grid-gap: 20px;
			justify-items: center;
			justify-content: center;
			padding: 20px 0;
			max-width: 1200px;
			margin: 0 auto;

			@include tablet {
				grid-template-columns: repeat(3, 1fr); }
			@include desktop {
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 40px; }
			@include fullhd {
				grid-gap: 40px; } } }

	&__show {
		button {
			background: $servicios-primary !important;
			max-width: 315px;
			width: 100%;
			margin: 0 auto;
			&:hover {
        background: $servicios-hover !important; } }

		&--less {
			display: none;
			button {
        &:hover {
          background: $servicios-hover !important; } } } } }


