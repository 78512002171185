// .......................................................................... { noticias recientes main } #
#promo-app {
  padding: 20px 20px 0;
  background: url('../images/promo_app/bg_promo_app.jpg');
  background-size: cover;
  position: relative;
  @include md {
    padding: 60px 20px 90px; }

  .date {
    left: 2px;
    top: 1px; } }

.block-app {
  margin-top: 20px;

  &__info {
    color: $cb;
    text-align: left; }

  &__title {
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.1rem;
    margin-bottom: 30px;
    @include md {
      font-size: 1rem; } }

  &__par {
    font-size: 1.1rem;
    line-height: 1.1rem;
    color: $cb;
    max-width: 190px;
    @include xs {
      max-width: 96%;
      font-size: 1.8rem;
      line-height: 2rem; }
    @include sm {
      max-width: calc(100% - 200px); }
    @include md {
      font-size: 2rem;
      line-height: 2.2rem;
      width: 100%; }

    br {
      display: none;
      @include md {
        display: block; } } }


  .logos {
    margin-top: 70px;
    &__logo {
      margin: 5px 9px 5px 0;
      width: 138px;
      display: block;
      @include sm {
        display: inline-block;
        width: 140px; }
      @include md {
        width: 160px; } } }

  &__figure {
    position: absolute;
    display: block;
    margin-top: 2rem;
    bottom: -6px;
    right: 0;
    @include sm {
      bottom: -6px; }
    @include lg {
      right: 20%; } }

  &__img {
    max-width: 150px;
    width: 100%;
    @include sm {
      max-width: 220px; }
    @include md {
      max-width: 350px; } } }
