// $icomoon-font-family: "icomoon" !default;
// $icomoon-font-path: "fonts" !default;
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icons/icomoon/" !default;

$icon-folder: "\e94e";
$icon-file-text: "\e94f";
$icon-file: "\e94f";
$icon-document: "\e94f";
$icon-list: "\e94f";
$icon-paper: "\e94f";
$icon-page: "\e94f";
$icon-info: "\e950";
$icon-information: "\e950";
$icon-action-export: "\e900";
$icon-action-back: "\e901";
$icon-action-call: "\e902";
$icon-archivos: "\e903";
$icon-action-complete: "\e904";
$icon-action-edit: "\e905";
$icon-action-notifications: "\e906";
$icon-action-respond: "\e907";
$icon-action-send-mail: "\e908";
$icon-action-send-message: "\e909";
$icon-descargar: "\e90a";
$icon-action-share: "\e90b";
$icon-filter-end-ticket: "\e90c";
$icon-filter-progress: "\e90d";
$icon-filter-ticket-open: "\e90e";
$icon-icon-alert: "\e90f";
$icon-icon-attach: "\e910";
$icon-icon-image-copia: "\e911";
$icon-icon-image: "\e912";
$icon-icon-upload: "\e913";
$icon-menu-add-closer: "\e914";
$icon-menu-add: "\e915";
$icon-menu-burger: "\e916";
$icon-menu-filter: "\e917";
$icon-menu-history: "\e918";
$icon-menu-profile: "\e919";
$icon-menu-reports: "\e91a";
$icon-menu-search: "\e91b";
$icon-menu-ticket: "\e91c";
$icon-state-due-date: "\e91d";
$icon-state-inbox-date: "\e91e";
$icon-pqr-icon: "\e91f";
$icon-filter-gobal: "\e920";
$icon-adjuntar: "\e921";
$icon-admin_tramites: "\e922";
$icon-almacenes: "\e923";
$icon-buscar: "\e924";
$icon-calendario: "\e925";
$icon-carpeta: "\e926";
$icon-cerrar: "\e927";
$icon-chevron: "\e928";
$icon-contactos: "\e929";
$icon-editar: "\e92a";
$icon-eliminar: "\e92b";
$icon-enviados: "\e92c";
$icon-manuales: "\e92d";
$icon-mensaje-nuevo: "\e92e";
$icon-menu: "\e92f";
$icon-nuevo: "\e930";
$icon-perfil: "\e931";
$icon-recibidos: "\e932";
$icon-reportes: "\e933";
$icon-responder: "\e934";
$icon-salir: "\e935";
$icon-tramites: "\e936";
$icon-ver: "\e937";
$icon-check: "\e938";
$icon-cambiar-contrasena: "\e939";
$icon-calendar: "\e93a";
$icon-check1: "\e93b";
$icon-chevron1: "\e93c";
$icon-cross: "\e93d";
$icon-email: "\e93e";
$icon-facebook: "\e93f";
$icon-filters: "\e940";
$icon-heart: "\e941";
$icon-hour: "\e942";
$icon-instagram: "\e943";
$icon-marker: "\e944";
$icon-minus: "\e945";
$icon-phone: "\e946";
$icon-pinterets: "\e947";
$icon-plus: "\e948";
$icon-search: "\e949";
$icon-twitter: "\e94a";
$icon-world: "\e94b";
$icon-youtube: "\e94c";
$icon-camera: "\e94d";
$icon-home: "\e94e";




@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?g90cze');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?g90cze#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?g90cze') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g90cze') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?g90cze##{$icomoon-font-family}') format('svg') {}
  font-weight: normal;
  font-style: normal;
  display: block; }



[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }


.icon-folder {
  &:before {
    content: $icon-folder; } }


.icon-file-text {
  &:before {
    content: $icon-file-text; } }


.icon-file {
  &:before {
    content: $icon-file; } }


.icon-document {
  &:before {
    content: $icon-document; } }


.icon-list {
  &:before {
    content: $icon-list; } }


.icon-paper {
  &:before {
    content: $icon-paper; } }


.icon-page {
  &:before {
    content: $icon-page; } }


.icon-info {
  &:before {
    content: $icon-info; } }


.icon-information {
  &:before {
    content: $icon-information; } }


.icon-action-export {
  &:before {
    content: $icon-action-export; } }


.icon-action-back {
  &:before {
    content: $icon-action-back; } }


.icon-action-call {
  &:before {
    content: $icon-action-call; } }


.icon-archivos {
  &:before {
    content: $icon-archivos; } }


.icon-action-complete {
  &:before {
    content: $icon-action-complete; } }


.icon-action-edit {
  &:before {
    content: $icon-action-edit; } }


.icon-action-notifications {
  &:before {
    content: $icon-action-notifications; } }


.icon-action-respond {
  &:before {
    content: $icon-action-respond; } }


.icon-action-send-mail {
  &:before {
    content: $icon-action-send-mail; } }


.icon-action-send-message {
  &:before {
    content: $icon-action-send-message; } }


.icon-descargar {
  &:before {
    content: $icon-descargar; } }


.icon-action-share {
  &:before {
    content: $icon-action-share; } }


.icon-filter-end-ticket {
  &:before {
    content: $icon-filter-end-ticket; } }


.icon-filter-progress {
  &:before {
    content: $icon-filter-progress; } }


.icon-filter-ticket-open {
  &:before {
    content: $icon-filter-ticket-open; } }


.icon-icon-alert {
  &:before {
    content: $icon-icon-alert; } }


.icon-icon-attach {
  &:before {
    content: $icon-icon-attach; } }


.icon-icon-image-copia {
  &:before {
    content: $icon-icon-image-copia; } }


.icon-icon-image {
  &:before {
    content: $icon-icon-image; } }


.icon-icon-upload {
  &:before {
    content: $icon-icon-upload; } }


.icon-menu-add-closer {
  &:before {
    content: $icon-menu-add-closer; } }


.icon-menu-add {
  &:before {
    content: $icon-menu-add; } }


.icon-menu-burger {
  &:before {
    content: $icon-menu-burger; } }


.icon-menu-filter {
  &:before {
    content: $icon-menu-filter; } }


.icon-menu-history {
  &:before {
    content: $icon-menu-history; } }


.icon-menu-profile {
  &:before {
    content: $icon-menu-profile; } }


.icon-menu-reports {
  &:before {
    content: $icon-menu-reports; } }


.icon-menu-search {
  &:before {
    content: $icon-menu-search; } }


.icon-menu-ticket {
  &:before {
    content: $icon-menu-ticket; } }


.icon-state-due-date {
  &:before {
    content: $icon-state-due-date; } }


.icon-state-inbox-date {
  &:before {
    content: $icon-state-inbox-date; } }


.icon-pqr-icon {
  &:before {
    content: $icon-pqr-icon; } }


.icon-filter-gobal {
  &:before {
    content: $icon-filter-gobal; } }


.icon-adjuntar {
  &:before {
    content: $icon-adjuntar; } }


.icon-admin_tramites {
  &:before {
    content: $icon-admin_tramites; } }


.icon-almacenes {
  &:before {
    content: $icon-almacenes; } }


.icon-buscar {
  &:before {
    content: $icon-buscar; } }


.icon-calendario {
  &:before {
    content: $icon-calendario; } }


.icon-carpeta {
  &:before {
    content: $icon-carpeta; } }


.icon-cerrar {
  &:before {
    content: $icon-cerrar; } }


.icon-chevron {
  &:before {
    content: $icon-chevron; } }


.icon-contactos {
  &:before {
    content: $icon-contactos; } }


.icon-editar {
  &:before {
    content: $icon-editar; } }


.icon-eliminar {
  &:before {
    content: $icon-eliminar; } }


.icon-enviados {
  &:before {
    content: $icon-enviados; } }


.icon-manuales {
  &:before {
    content: $icon-manuales; } }


.icon-mensaje-nuevo {
  &:before {
    content: $icon-mensaje-nuevo; } }


.icon-menu {
  &:before {
    content: $icon-menu; } }


.icon-nuevo {
  &:before {
    content: $icon-nuevo; } }


.icon-perfil {
  &:before {
    content: $icon-perfil; } }


.icon-recibidos {
  &:before {
    content: $icon-recibidos; } }


.icon-reportes {
  &:before {
    content: $icon-reportes; } }


.icon-responder {
  &:before {
    content: $icon-responder; } }


.icon-salir {
  &:before {
    content: $icon-salir; } }


.icon-tramites {
  &:before {
    content: $icon-tramites; } }


.icon-ver {
  &:before {
    content: $icon-ver; } }


.icon-check {
  &:before {
    content: $icon-check; } }


.icon-cambiar-contrasena {
  &:before {
    content: $icon-cambiar-contrasena; } }


.icon-calendar {
  &:before {
    content: $icon-calendar; } }


.icon-check1 {
  &:before {
    content: $icon-check1; } }


.icon-chevron1 {
  &:before {
    content: $icon-chevron1; } }


.icon-cross {
  &:before {
    content: $icon-cross; } }


.icon-email {
  &:before {
    content: $icon-email; } }


.icon-facebook {
  &:before {
    content: $icon-facebook; } }


.icon-filters {
  &:before {
    content: $icon-filters; } }


.icon-heart {
  &:before {
    content: $icon-heart; } }


.icon-hour {
  &:before {
    content: $icon-hour; } }


.icon-instagram {
  &:before {
    content: $icon-instagram; } }


.icon-marker {
  &:before {
    content: $icon-marker; } }


.icon-minus {
  &:before {
    content: $icon-minus; } }


.icon-phone {
  &:before {
    content: $icon-phone; } }


.icon-pinterets {
  &:before {
    content: $icon-pinterets; } }


.icon-plus {
  &:before {
    content: $icon-plus; } }


.icon-search {
  &:before {
    content: $icon-search; } }


.icon-twitter {
  &:before {
    content: $icon-twitter; } }


.icon-world {
  &:before {
    content: $icon-world; } }


.icon-youtube {
  &:before {
    content: $icon-youtube; } }


.icon-camera {
  &:before {
    content: $icon-camera; } }

.icon-home {
  &:before {}
  content: $icon-home; }

