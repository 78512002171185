$cp1: #903392;

$icon-dimensions: 1.5rem !default;
$icon-dimensions-small: 1rem !default;
$icon-dimensions-medium: 2rem !default;
$icon-dimensions-large: 3rem !default;

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: $icon-dimensions;
  width: $icon-dimensions;

  &--white {
    color: white !important; }
  &--rotate__180 {
    transform: rotate(180deg) !important; }
  &--primary {
    color: $cp1 !important; }

  &__background {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #e7e7e7;
    padding: 15px;
    &--large {
      width: 6em;
      height: 6em; }

    &--transparent {
      background-color: transparent; } }

  &.is-font-large {
    font-size: 3em; }

  // Sizes
  &.is-small {
    height: $icon-dimensions-small;
    width: $icon-dimensions-small; }
  &.is-medium {
    height: $icon-dimensions-medium;
    width: $icon-dimensions-medium; }
  &.is-large {
    height: $icon-dimensions-large;
    width: $icon-dimensions-large; } }
