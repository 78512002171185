// .......................................................................... { man header styles } #

.main-header {
  // position: relative
  background-image: url(../images/nosotros/background_nosotros.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 21rem;
  padding-top: 1.2em;
  z-index: 0;

  @include sm {
    display: flex;
    align-items: center;
    flex-direction: column; }

  @include md {
    justify-content: center; }

  &.m--inner {
    height: 18rem;
    @include md {
      height: 21rem; }

    .inner-logo {
       .inner-logo__logo {
         width: 150px;
         height: 150px;
         background-position: center center;
         background-repeat: none;
         background-size: cover;
         border-radius: 50%; } } }

  &__no-padding {
    padding: 0 !important; }

  &__no-background {
    background-image: none;
    height: auto !important;
    background-color: transparent;
    .back-title {
      color: rgba($cg4, 0.3) !important; } }

  .back-title {
    display: block;
    width: 100%;
    height: 4rem;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(white, 0.8);
    font-weight: 800;
    font-size: 3.3rem;
    letter-spacing: 0.08rem;
    text-shadow: 0 0 0.5rem rgba($cp6, 0.05);
    @include md {
      &__align-left {
        justify-content: flex-start; } }

    &__main-title {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $cg1;
      font-weight: 600;
      letter-spacing: 0.1rem;
      text-align: center;
      top: 0;
      right: 0;
      left: 0;
      @include md {

        &__align-left {
          justify-content: flex-start; } } } }

  .primary-title {
    display: block;
    width: 100%;
    height: 4rem;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(white, 0.8);
    font-weight: 800;
    font-size: 3.3rem;
    letter-spacing: 0.08rem;
    text-shadow: 0 0 0.5rem rgba($cp6, 0.05);
    @include md {
      &__align-left {
        justify-content: flex-start; } }

    &__main-title {
      align-items: center;
      color: $cp1;
      display: flex;
      font-size: 1rem;
      font-weight: 600;
      height: 100%;
      justify-content: center;
      left: 0;
      letter-spacing: 0.1rem;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 1;
      @include md {

        &__align-left {
          justify-content: flex-start; } } } }


  .main-subtitle {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0.3rem 0 0;
    font-weight: 300;
    font-size: 0.8rem;
    padding: 0 10px;
    &__align-left {
      text-align: left; }
    @include sm {
      font-size: 0.9rem; } }

  &--marketplace {
    background-image: url(../images/background/bg-marketplace.png);
    @include md {
      height: 15rem; }
    @include lg {
      height: 20rem; }
    & .back-title__main-title {
      font-size: 30px;
      color: #FF6D3D;
      font-weight: bold;
      letter-spacing: 0; }
    & .search-box {
      padding: 0 1rem; } } }


