// ............................................ { almacenes } #
#almacenes {

  &.block-info {
    padding-bottom: 0;
    padding: 0 20px;
    @include md {
      padding-bottom: 90px; }
    &__content {
      padding: 40px 20px; }
    @include sm {
      padding-top: 40px;
      border-top: 1px solid $cp9; } }

  figure.sblock-al__img {
    display: block;
    max-width: 554px;
    width: 100%;
    height: 244px;
    background-position: center center;
    background-size: cover;
    background-image: url(../images/almacenes/default_slide_des.jpg);
    @include sm {
      max-width: 100%;
      padding: 40px; } }

  .box-redes {
    .columns {
      justify-content: center;
      @include md {
        justify-content: center; } }
    // .column.is-3
    //   width: 33%
    //   @include lg
 }    //     width: 22%

  .nuestras-promociones.section {
    padding: 3rem 1rem 2rem;
    background-color: $cp7;
    @include md {
      padding: 3rem 1rem 1rem; }
    @include xl {
      padding: 3rem 0 1rem; } }


  .slider-wrapper {
    &--almacen {
      > .slider-main > .slick-list {
          border-radius: 10px; }
      > .slider-main > .slick-dots {
          position: relative;
          top: 0;
          & li > button::before {
            color: silver; }
          & li.slick-active > button::before {
            color: $cp1; } } } }

  .slider-wrapper {
    .block-nd {
      margin-top: 40px;
      padding-top: 20px;
      margin-bottom: 40px;

      &__items {
        margin: 0 10px;
        max-width: 265px;
        width: 100%; }
      &__footer {
        min-height: 60px; }

      .slick-arrow {
        background: $cb;
        &::before {
          color: $cn; } } } } }



// ............................................ filters

.filter {
  background: $cb;
  border-radius: 10px;
  @include sm {
    margin-top: 0; }

  .field:not(:last-child) {
    margin-bottom: 0rem; }

  &__head {
    border-bottom: 1px solid  $cp9;
    padding: 20px 20px 30px;

    &--mobile {
      border-bottom: 1px solid  $cp9;
      padding: 10px 20px;
      background: $cp1;
      color: $cb;
      position: relative;
      cursor: pointer;
      display: block;
      border-radius: 10px;

      @include lg {
        display: none; }

      .icon-menu {
        position: absolute;
        top: 12px;
        right: 9px; }

      .filter__title {
        color: $cb; } } }

  &__title {
    font-weight: 600;
    font-size: 0.8rem;
    color: $cn;
    &--black {
      @extend .filter__title;
      color: $cn;
      padding: 0 0 10px; }
    &--regular {
      @extend .filter__title;
      font-weight: 500; } }

  &__content {
    padding: 40px 20px 0;
    @include sm {
      padding: 20px 20px 0; }

    &--main {
      border-bottom: 1px solid  $cp9;
      padding: 20px 20px 30px;
      display: none;
      // height: 90vh
      // overflow-y: scroll

      @include lg {
        display: block;
        height: auto; } } }

  &__link {
    color: $cn;
    display: block;
    font-size: 0.9rem;
    margin: 0 0 1.5rem;

    &.active,
    &:hover {
      color: $cp1; } }

  &__footer {
    // position: absolute
    margin-top: 1em;
    width: 100%;
    @include md {
      display: none; } }

  // form - checkbox
  .field {
    .control {
      label {
        font-weight: 300;
        font-size: 0.9rem;

        // text-transform: capitalize
        //
        &:first-letter {
          text-transform: capitalize; } } } } }

// ............................................ block almacenes

.block-al {
  &__title {
    font-size: 0.9rem;
    font-weight: 700;
    width: 90%;


    text-transform: none;
    margin-bottom: .8em;
    &::first-letter {
      text-transform: uppercase; }
    &:hover {
      color: $cp1; } }

  .icon-1 {
    position: absolute;
    background: none;
    width: 40px;
    height: 40px;
    border: none;
    box-shadow: none;
    z-index: 2;
    top: 10px;
    right: 7px;
    color: $cp9;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover,
    &.active {
      color: $youtube; }
    &:focus {
      border: none;
      outline: none; } }

  &__par, p {
    font-size: 0.9rem;
    font-weight: normal;
    margin-block-end: 1em;
    text-transform: lowercase;
    color: $cg6;
    &::first-letter {
      text-transform: uppercase; } }

  &__items {
    width: 100%;
    display: block;
    @include sm {
      width: 49%;
      display: inline-block; }
    @include md {
      width: 33%;
      display: inline-block; } }

  &__link {
    background: $cb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // color: $cp5
    border-radius: 10px;
    @include md {
      height: 355px; }
    // &__item
    //     background: $cb
    //     display: block
    //     border-radius: 10px
    //     height: 353px
    // &:hover .block-al__footer
    //   border-top: 1px solid $cp1
    //   background: $cp1
    //   color: $cb !important

    // &:hover .block-al__footer a
    //   color: $cb !important

    // &:hover .block-al__footer h3 a
    //   color: $cp5 !important

    &--campain {
      background: $cb;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // color: $cp5
      border-radius: 10px; } }


  &__figure {
    // padding: 0
 }    // margin: auto

  &__img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-height: 480px;
    object-fit: cover; }



  &__content {
    padding: 20px 20px 2px;
    position: relative; }

  &__footer {
    border-top: 1px solid  $cp9;
    color: $cp1;
    cursor: pointer;
    padding: 10px 20px 10px 50px;
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
    font-weight: 400;
    transition: all 0.3s ease;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    a {
      color: $cp1; }
    &:hover {
      border-top: 1px solid $cp1;
      background: $cp1;
      color: $cb !important; }


    .icon-2 {
      position: absolute;
      left: 14px;
      top: 22%;
      font-size: 1.3rem; }
    .local {
      font-weight: 600; } } }

// ............................................ detalle - almacenes

.detalle {
  padding: 40px 0 0;
  background: $cb;
  text-align: center;
  margin-bottom: 20px;

  @include sm {
    padding: 40px 20px 0; }

  @include lg {
    text-align: left; }

  &__title {
    color: $cn;
    font-size: 1.5rem;
    font-weight: 600; }

  &__subtitle {
    font-size: 0.8rem;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase; } } }

.inner-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  @include md {
    padding-top: 0; }
  @include lg {
    justify-content: flex-start; } }


// ............................................ info - almacenes
#almacen-info {
  // margin-top: 50px

  &.section {
    padding: 0; } }

.almacen-info {
  background: $cb;
  // padding-bottom: 40px

  @include md {
    padding-bottom: 0; }

  &__title {
    font-weight: 900;
    font-size: 1rem;
    color: $cg1;
    text-align: center;
    margin-bottom: 2em;
    @include md {
      padding: 0 0 2rem; } }

  &__icons {
    padding: 2rem 1rem;
    @include md {
      padding: 2rem; } }

  &__link {
    color: $cg1;
    &:hover {
      color: $cp1; }

    &.active {
      color: $cp8; }

    span {
      display: inline-block;
      font-weight: 500; } }

  &__item {
    font-size: 0.8rem;
    font-weight: 300;
    color: $cg1;
    display: flex;

    &.active .icon {
      color: $cn;
      border: solid 1px $cn; }

    span {
      display: inline-block;
      font-weight: 500; }

    a:not(.almacen-info__link) {
      color: $cp1; }

    .icon {
      // width: 2.8rem
      // height: 2.8rem
      padding: 20px;
      border: none;
      background-color: $cp9;
      color: $cp8;
      display: inline-block;
      font-size: 1.5rem;
      margin-right: 1rem;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      display: inline-flex; }
    .icon-facebook {
      border: solid 1px $facebook;
      background-color: $facebook;
      color: white; }
    .icon-twitter {
      border: solid 1px $twitter;
      background-color: $twitter;
      color: white; }
    .icon-pinterets {
      border: solid 1px $pinterest;
      background-color: $pinterest;
      color: white; }
    .icon-youtube {
      border: solid 1px $youtube;
      background-color: $youtube;
      color: white; }
    .icon-instagram {
      border: none;
      background: rgba(254,217,117,1);
      background: -moz-linear-gradient(45deg, rgba(254,217,117,1) 0%, rgba(250,126,30,1) 25%, rgba(214,41,119,1) 50%, rgba(150,47,191,1) 75%, rgba(79,90,213,1) 100%);
      background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(254,217,117,1)), color-stop(25%, rgba(250,126,30,1)), color-stop(50%, rgba(214,41,119,1)), color-stop(75%, rgba(150,47,191,1)), color-stop(100%, rgba(79,90,213,1)));
      background: -webkit-linear-gradient(45deg, rgba(254,217,117,1) 0%, rgba(250,126,30,1) 25%, rgba(214,41,119,1) 50%, rgba(150,47,191,1) 75%, rgba(79,90,213,1) 100%);
      background: -o-linear-gradient(45deg, rgba(254,217,117,1) 0%, rgba(250,126,30,1) 25%, rgba(214,41,119,1) 50%, rgba(150,47,191,1) 75%, rgba(79,90,213,1) 100%);
      background: -ms-linear-gradient(45deg, rgba(254,217,117,1) 0%, rgba(250,126,30,1) 25%, rgba(214,41,119,1) 50%, rgba(150,47,191,1) 75%, rgba(79,90,213,1) 100%);
      background: linear-gradient(45deg, rgba(254,217,117,1) 0%, rgba(250,126,30,1) 25%, rgba(214,41,119,1) 50%, rgba(150,47,191,1) 75%, rgba(79,90,213,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fed975', endColorstr='#4f5ad5', GradientType=1 );
      color: white; } }
  .box-almacenes {
    .almacen-info__item.active span {
      color: $cp1; }
    .almacen-info__item.active .icon, {
      color: $cp1;
      border: solid 1px $cp1; } } }

.almacen-horario {
  background: $cb;
  padding-bottom: 250px;

  &__row {
    @include sm;
    text-align: center;

    @include lg {
      text-align: left; } }

  &__title {
    color: $cg1;
    margin: 2em 0;
    font-weight: bold;

    @include sm;
    text-align: center;

    @include lg {
      text-align: left; } }

  &__date {
    font-weight: bold;
    font-size: 0.9rem;
    display: block;
    width: 100%; }

  &__hours {
    font-weight: 300;
    font-size: 0.9rem;
    display: block;
    width: 100%; } }

// ............................................ nuestras promociones - almacenes

.nuestras-promociones {
  padding-top: 40px;

  .slider-wrapper {}

  .block-nd {
    &__items {
      background: $cb;
      border-radius: 10px; }

    &__img {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px; } } }

// .................. {  box-add / favorite, map }

.box-add {
  padding-top: 6px;
  &__map .icon,
  &__favorites .icon, {

      font-size: 1rem;
      vertical-align: middle;
      background-color: $cp9;
      color: $cg2;
      border-radius: 0.9rem;
      height: 1.8rem;
      width: 1.8rem;
      margin-right: 0.5rem;
      &.active {
        color: $cb;
        background-color: $cp1; } }

  // &__map .icon
  //   background-color: $cp1
  //   color: $cb

  &__link {
    color: $cp1;
    font-size: 0.8rem;
    font-weight: bold; } }

// .................. {  default }

.default {
  &.m--margin {
    margin-top: -150px; }
  &__figure {
    text-align: center;
    padding: 3rem 2rem; }
  &__img {
    width: 150px; }
  &__subtilte {
    font-size: 0.8rem; } }


// ................................... { block descargas }

.block-descarga {
  margin: 0 auto;
  width: 100%;
  @include md {
    margin: 50px auto; }

  &__content {
    background: $cb;
    padding: 20px; }

  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $cn;
    padding-top: 30px;
    @include md {
      font-size: 1.1rem; } }

  &__buttons {
    padding: 10px 0;
    @include md {
      padding: 30px 0; }

    .button {
      max-width: 300px; } } }


.contenedor__alamacenes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px; }

.almacenes__container {
  grid-column-start: 2;
  grid-column-end: 5; }


.filter--marketplace {
  & .check__input label:before,
  & .check__input label:after {
    border-radius: 20px; }
  & .filter__head--mobile {
    background-color: #FF6D3D; } }
