$modal-invoices-z: 1300 !default;
$modal-invoices-background: rgba($black, 0.6) !default;
$modal-invoices-background-content: #FAFAFA !default;
$modal-invoices-background-alert-box: #ffffff !default;
$modal-invoices-background-image: #dadada !default;
$modal-invoices-close-shadow: 4px 4px 10px -6px rgba(0,0,0,0.75)!default;
$modal-invoices-shadow: 0px 3px 10px 0px #f3f3f3!default;
$modal-invoices-paragraph: #333!default;
$modal-invoices-approved: #26C74B!default;
$modal-invoices-disapproved: #D60D2C !default;


.modal-invoices {
    z-index: $modal-invoices-z;
    position: absolute;
    top: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: none;

    @include desktop {
        position: fixed;
        top: 0; }


    &__background {
        background: $modal-invoices-background;
        width: 100%;
        height: 100%;
        display: none;
        top: 0;
        left: 0;

        @include desktop {
            display: block;
            position: fixed;
            overflow: hidden; } }

    &__content {
        background: $modal-invoices-background-content;
        width: 100%;
        height: 100%;
        margin-top: 80px;
        padding-bottom: 80px;
        overflow: scroll;
        position: fixed;

        @include desktop {
            display: flex;
            width: 90%;
            height: 90%;
            max-width: 1400px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: 0;
            padding-bottom: 0;
            border-radius: 22px; }


        &__arrows {
            background: $modal-invoices-background-content;
            display: flex;
            width: 34px;
            height: 34px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            box-shadow: $modal-invoices-close-shadow;
            position: absolute;
            top: 25%;
            margin: 10px;
            color: $black;
            cursor: pointer;

            @include desktop {
                top: 50%; }

            &__left {
                left: 0; }

            &__right {
                right: 0;
                transform: scaleX(-1); } }


        &__image {
            background: $modal-invoices-background-image;
            flex: 0 1 50%;

            @include desktop {
                display: flex;
                align-items: center; }
            img {
                max-width: 400px;
                width: 100%;
                display: block;
                margin: 0 auto; }

            &__bottom {
                background: $modal-invoices-background-content;
                display: flex;
                width: 34px;
                height: 34px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                box-shadow: $modal-invoices-close-shadow;
                position: absolute;
                top: 0;
                right: 0;
                margin: 10px;
                color: $black;
                cursor: pointer;

                @include desktop {
                    margin: 20px; }

                b {
                    font-size: 1.2rem; }

                &__download {
                    align-items: center;
                    background: $modal-invoices-background-content;
                    border-radius: 50%;
                    box-shadow: $modal-invoices-close-shadow;
                    color: $black;
                    cursor: pointer;
                    display: none;
                    height: 34px;
                    justify-content: center;
                    margin: 20px;
                    position: absolute;
                    right: 50%;
                    top: 0;
                    width: 34px;

                    @include desktop {
                        display: flex; }

                    img {
                        max-width: 16px; } } } }
        &__container {

            @include desktop {
                flex: 0 0 50%;
                display: flex;
                align-items: center; }

            .invoice-info {
                padding: 25px 20px;

                @include desktop {
                    padding: 60px 80px; }

                &__detail {

                    &:not(:last-child) {
                        padding-bottom: 25px; }


                    &__title {
                        font-size: .9rem;
                        font-weight: 700; }

                    &__paragraph {
                        font-size: .9rem;
                        font-weight: 400;
                        color: $modal-invoices-paragraph;

                        &--approved {
                            color: $modal-invoices-approved; }

                        &--disapproved {
                            color: $modal-invoices-disapproved; } } }

                &__alert {
                    background: $modal-invoices-background-alert-box;
                    padding: 10px;
                    border-radius: 2.79px;

                    &__title {
                        font-size: 1rem;
                        font-weight: 700;
                        color: $modal-invoices-disapproved;
                        padding-bottom: 8px; }

                    &__paragraph {
                        font-size: 1rem;
                        font-weight: 400;
                        color: $modal-invoices-disapproved;
                        text-align: justify; } } } } } }
