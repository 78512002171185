$servicios-card-box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);
$servicios-card-hover: #FF9733;
$servicios-card-alternative: #FAFAFA;
$servicios-card-bg: #fff;
$servicios-font-color-secondary: #B3B3B3;
$servicios-font-color-primary: #333333;


$slider-main-green: #FF9733;

.servicios-card {
    position: relative;
    transition: ease all .3s;
    margin: 0 auto 30px auto;
    display: none;
    -webkit-box-shadow: $servicios-card-box-shadow;
    -moz-box-shadow: $servicios-card-box-shadow;
    box-shadow: $servicios-card-box-shadow;
    -webkit-appearance: none;
    border-radius: 10px;

    &:hover {
        transform: scale(1.02);
        color: $servicios-card-hover; }

    &:nth-child(-n+5) {
        display: block; }
    @include fullhd {
        &:nth-child(-n+16) {
            display: block; } }

    &__img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        max-width: 315px; }

    &__title {
        padding: 10px 20px;
        font-size: .9rem;
        font-weight: bold;
        color: $servicios-card-hover;
        padding-bottom: 40px; } }
