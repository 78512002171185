

// .......................................................................... { followers } #
.followers {
  .columns.is-mobile > .column {
    width: 16%;
    margin: 7px 0 0 auto;
    padding: 0 !important; }

  &__link {
    color: $cp1;
    font-size: 1.2rem;
    text-align: center;
    background: $cg4;
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    margin: 0;
    border-radius: 15px;

    &--search {
      background: transparent !important; }

    &:hover {
      background: $cp1;
      color: $cb; }

    &.followers__footer {
      background-color: $cp8;
      color: $cb;
      font-size: 1rem;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin-right: 5px;

      &:hover {
        background-color: $cp7;
        color: $cp1;
        transition: ease all .8s; } } } }
