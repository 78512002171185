
.background-new {
    position: relative;

    &::after {
        content: "";
        bottom: -100%;
        left: 0;
        position: absolute;
        height: 101%;
        width: 100%;
        z-index: 0; }

    &--purple {
        background: url(../images/background/bg-purple-mobile.jpg) no-repeat center 0;
        background-size: cover;
        @include tablet {
            background: url(../images/background/bg-purple.jpg) no-repeat center 0;
            background-size: cover; }

        &::after {
            background: url(../images/background/wave-purple.svg) no-repeat center 0; } }

    &--green {
        background: url(../images/background/bg-green.jpg) no-repeat center 100%;
        background-size: cover;
        @include mobile {
            background: url(../images/background/bg-green.jpg) no-repeat center 0;
            background-size: cover; }
        &::after {
            background: url(../images/background/wave-green.svg) no-repeat center 100%;
            background-size: cover; } }

    &--orange {
        background: url(../images/background/bg-orange.jpg) no-repeat center 100%;
        background-size: cover;
        @include mobile {
            background: url(../images/background/bg-orange.jpg) no-repeat center 0;
            background-size: cover; }
        &::after {
            background: url(../images/background/wave-orange.svg) no-repeat center 100%;
            background-size: cover; } }

    &--pink {
        background: url(../images/background/bg-pink.jpg) no-repeat center 100%;
        background-size: cover;
        @include mobile {
            background: url(../images/background/bg-pink.jpg) no-repeat center 0;
            background-size: cover; }
        &::after {
            background: url(../images/background/wave-pink.svg) no-repeat center 100%;
            background-size: cover; } }

    &--blue {
        background: url(../images/background/bg-blue.jpg) no-repeat center 100%;
        background-size: cover;
        @include mobile {
            background: url(../images/background/bg-blue.jpg) no-repeat center 0;
            background-size: cover; }
        &::after {
            background: url(../images/background/wave-blue.svg) no-repeat center 100%;
            background-size: cover; } }

    &--yellow {
        background: url(../images/background/bg-yellow.jpg) no-repeat center 100%;
        background-size: cover;
        @include mobile {
            background: url(../images/background/bg-yellow.jpg) no-repeat center 0;
            background-size: cover; }
        &::after {
            background: url(../images/background/wave-yellow.svg) no-repeat center 100%;
            background-size: cover; } } }

$main-bg: #ffffff;
$main-bg-noticias: #F8F9FA;
$main-bg-promociones: #F8F9FA;
$main-bg-servicios: #333333;

.main-bg {
    background: $main-bg;
    padding-top: 50px;
    &--noticias {
        background: $main-bg-noticias;
        padding-bottom: 10px; }
    &--promociones {
        background: url(../images/background/bg-home.jpg) no-repeat center  0;
        background-size: cover;
        padding-bottom: 10px; }
    &--servicios {
        background: $main-bg-servicios;
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 50px;

        @include desktop {
            padding-bottom: 250px; } }
    &__width {
        max-width: 1200px;
        margin: 0 auto; } }
