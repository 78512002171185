@charset "utf-8";

// Update Bulma's global variables
$family-sans-serif: 'Open Sans', sans-serif;
$widescreen-enabled: true;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// .......................................................................... { global routers } #
$r_global: "../images";

html {
  font-family: 'Open Sans', sans-serif; }

body {
  overflow-x: hidden;
  background-color: white; }
p {
  font-weight: 500;
  color: $cp11; }

// .......................................................................... { grid main } #

#cont_pzi {
  //Height of the main nav for some pages with content with {position: absolute}
  // padding-top: 113px

  padding-top: 105px;
  // margin-top: 80px
  overflow: hidden;
  @media (max-width: 1199px) {
    padding-top: 80px;
    // margin-top: 100px
    // 240px
 } }    // 80px

// .......................................................................... { all sliders } #
.slider-wrapper {
  max-width:  100%;
  width: 100%;
  position: relative; }


// .......................................................................... { show / hidden } #
.show-mobile {
  display: block;
  @include md {
    display: block; }
  @include lg {
    display: none; } }

// .......................................................................... { box white } #
.box {
  &-white {
    padding: 1.5rem;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07); } }


// .......................................................................... { variables for help }

//$box_shadow : 0px 3px 10px 0px #f3f3f3

.container-shopping-mall-iframe,
.container-shopping-mall-iframe-app, {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	background: #e7e7e7;
	z-index: 1;


	iframe {
		width: 100%;
		height: 100%; } }

.container-shopping-mall-iframe {
	top: 115px;

	@media screen and (max-width: 1199px) {
		top: 154px; }

	@media screen and (max-width: 991px) {
		top: 80px; } }

.container-shopping-mall-iframe-app {
	top: 0; }
