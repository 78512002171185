$modal-intro-z: 99999 !default;
$modal-intro-background: rgba($black, 0.6) !default;
$modal-intro-background-content: #FAFAFA !default;
$modal-intro-header-title-color: #FFFfff !default;
$modal-intro-close-shadow: 4px 4px 10px -6px rgba(0,0,0,0.75)!default;
$modal-intro-shadow: 0px 3px 10px 0px #f3f3f3!default;
$modal-intro-border: 2px solid #f3f3f3!default;

.modal-intro {
    z-index: $modal-intro-z;
    position: fixed;
    top: 0px;
    left: 0;
    height: 100%;
    width: 100%;

    @include desktop {
        position: fixed;
        top: 0;
        overflow: hidden; }

    &__background {
        background: $modal-intro-background;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        @include desktop {
            display: block;
            position: fixed;
            overflow: hidden; } }

    &__content {
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 80px;
        padding-bottom: 80px;
        position: fixed;
        border-radius: 22px;
        max-width: 1600px;

        @include fullhd {
            width: initial;
            height: initial; }

        @include desktop {
            margin-top: 0;
            padding-bottom: 0;
            border-radius: 22px; }


        &__header {
            padding: 25px 20px;
            text-align: center;

            @include desktop {
                padding: 40px 45px; }

            &__image {
                position: relative; }
            &__button {
                background: $modal-intro-background-content;
                display: flex;
                width: 34px;
                height: 34px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                box-shadow: $modal-intro-close-shadow;
                -webkit-appearance: none;
                position: absolute;
                top: 40px;
                right: 35px;
                color: $black;
                cursor: pointer;

                @include desktop {
                    margin: 20px; }

                b {
                    font-size: 1.2rem; } } } } }
