
// .......................................................................... { color palette } #

// colors used { titan-plaza }
$cp1:     #903392;
$cp2:     #D14A6F;
$cp3:     #1A9C90;
$cp4:     #B4BA45;
$cp5:     #F06031;
$cp6:     #F69333;
$cp7:     #E0A1DD;
//$cp7-2:     #E0A1DD
$cp8:     #B337AD;
$cp9:     #FF6D3D;

// colors not used { plaza-imperial }
$cp9:     #EBECF0;
$cp10:    #E6CDD0;
$cp11:    #737680;
$cp12:    #FF3347;
$cp13:    #CF0619;

// ............... { tones } #

// colors used { titan-plaza }
$cb:    #fff;
$cn:    #000;

// colors used { titan-plaza }
$cg1:   #333333;
$cg2:   #B3B3B3;
$cg3:   #FAFAFA;
$cg4:   #E6E6E6;

// colors not used { plaza-imperial }
$cg5:   #333333;
$cg6:   #525252;
$cg7:   #808690;
$cg8:   #AEAEAE;
$cg9:   #737680;
$cg10:   #f8f9fa;
$cg11:   #F0F0F0;


// ............... { alrets } # { plaza-imperial }

$success: #52C40C;
$warning: #FFD340;
$error: #FF5050;

// ............... { social colors } # { plaza-imperial }

$facebook: #3B5998;
$twitter: #5FA9DD;
$pinterest: #c8232c;
$youtube: #FF0000;
$instagram: #5FA9DD;


// ............... { Extranet colors } # { plaza-imperial }

$uniBackground : rgb(250, 250, 250);
$uniGralText : rgb(141, 145, 153);
$uniGraySpoon : #A4A7AF;
$uniHeadingText : rgb(41, 44, 51);
$uniLineColor : rgb(53, 58, 66);
$uniTextColor : rgb(53, 58, 66);
$uniPrimaryColor : rgb(214, 13, 44);
$uniInputs : rgb(234, 235, 236);
$uniLightGray : #C4C6CC;
$uniNotificationGray : #E1E2E6;

// States { plaza-imperial }
$uniPending : #ED9200;
$uniApproved : #26C74B;
$uniRejected : #D60D2C;
$uniProgress : #21DDAA;
$uniSolved : #499DE5;
