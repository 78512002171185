.promo-modal {
	background: rgba(10,10,10,0.6);
	bottom: 0;
	display: block;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	right: 0;
	top: 0;
	user-select: none;
	z-index: 9999999999;
	// display: none
	transition: ease all .1s;

	&.is-active {
		display: block; }

	&-dialog {
		margin: 10px auto; // min-height
		max-width: #{600px + 10px}; //padding
		width: 100%;
		position: relative;
		pointer-events: none;
		padding: 0 10px; // max-width
		display: flex;
		align-items: center;
		min-height: calc(100% - (10px * 2)); } // margin

	&-content {
		position: relative;
		pointer-events: auto;
		width: 100%;
		box-shadow: 0 4px 10px rgba(0,0,0,0.1);

		img {
			display: block;
			width: 100%;
			border-radius: 1rem; } }

	&-button {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 34px;
		height: 34px;
		border: none;
		outline: none;
		background: $cb;
		line-height: 34px;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: 0 4px 10px rgba(0,0,0,0.1);
		color: #242424;
		font-size: 18px; } }
