$modal-request-z: 9999 !default;
$modal-request-background: rgba($black, 0.6) !default;
$modal-request-background-content: #FAFAFA !default;
$modal-request-header-title-color: #FFFfff !default;
$modal-request-close-shadow: 4px 4px 10px -6px rgba(0,0,0,0.75)!default;
$modal-request-shadow: 0px 3px 10px 0px #f3f3f3!default;
$modal-request-border: 2px solid #f3f3f3!default;

.modal-request {
    z-index: $modal-request-z;
    position: fixed;
    top: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: scroll;

    @include desktop {
        position: fixed;
        top: 0;
        overflow: hidden; }

    &__background {
        background: $modal-request-background;
        width: 100%;
        height: 100%;
        display: none;
        top: 0;
        left: 0;

        @include desktop {
            display: block;
            position: fixed;
            overflow: hidden; } }

    &__content {
        background: $modal-request-background-content;
        width: 100%;
        height: 100%;
        margin-top: 80px;
        padding-bottom: 80px;
        overflow: scroll;
        position: fixed;

        @include desktop {
            width: 90%;
            height: 90%;
            max-width: 1400px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: 0;
            padding-bottom: 0;
            border-radius: 22px; }


        &__header {
            background-image: url(../images/header-modal-request.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding: 25px 20px;
            color: $modal-request-header-title-color;
            font-weight: 700;
            position: relative;

            @include desktop {
                background-image: url(../images/header-modal-request-desktop.jpg);
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                font-size: 2rem;
                line-height: 2rem;
                padding: 40px 45px; }

            &__bottom {
                background: $modal-request-background-content;
                display: flex;
                width: 34px;
                height: 34px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                box-shadow: $modal-request-close-shadow;
                -webkit-appearance: none;
                position: absolute;
                top: 0;
                right: 0;
                margin: 10px;
                color: $black;
                cursor: pointer;

                @include desktop {
                    margin: 20px; }

                b {
                    font-size: 1.2rem; } } }

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include desktop {
                padding: 60px; }

            .request-title {
                text-align: center;
                font-weight: 700;
                padding: 20px; }

            .request-form {
                display: inline-block;
                width: 100%;
                padding: 0 20px 20px 20px;
                background: $modal-request-background-content;

                @include tablet {
                    padding: 0 80px 20px 80px; }

                @include desktop {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 0 25px;
                    padding-top: 20px; }

                &__block {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 20px;

                    &__label {
                        font-weight: 700;
                        padding-left: 20px;

                        @include desktop {
                            padding-left: 40px; } }

                    &__content {
                        border: none;
                        box-shadow: $modal-request-shadow;
                        -webkit-appearance: none;
                        height: 44px;
                        border-radius: 22px;
                        padding: 0 20px;


                        &--select {

                            background-position: 95% center;
                            background-repeat: no-repeat;
                            background-color: #fff;
                            background-image: url(../images/chevron-icon.png);
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            -o-appearance: none;
                            appearance: none;

                            &::-ms-expand {
                                display: none; } }

                        @include desktop {
                            padding-left: 40px; } }

                    &__alert {
                        color: red;
                        padding: 5px 10px 5px 20px;
                        font-size: .8rem; } } }

            .request-submit {
                width: 100%;
                padding: 0 20px;
                background: $modal-request-background-content;

                @include desktop {
                    max-width: 400px;
                    padding-bottom: 40px; }

                &__btn {
                    font-size: 1rem;
                    padding: 10px 0;
                    margin-top: 0px; } }

            .request-footer {
                display: flex;
                padding: 15px 20px;
                align-items: center;
                justify-content: center;
                border-top: $modal-request-border;
                width: 100%;
                margin: 20px 0;

                @include desktop {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 40px 40px; }

                &__icon {
                    padding-right: 10px; }

                &__text {
                    font-size: .9rem; } } } } }
