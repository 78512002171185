$promo-detail-bg: #fafafa;
$promo-detail-primary: #F06031;
$promo-detail-hover: #ff6d3d;
$promo-detail-circle: #EAEBEC;
$promo-detail-secondary-font: #B3B3B3;

.promo-detail {
    background: $promo-detail-bg;
    padding: 0 30px;

    &__header {
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 50px;

        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 0 50px 0; }

        &__button {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            max-height: 44px;
            background: $promo-detail-primary !important;
            &:hover {
                background: $promo-detail-hover !important; }

            @include tablet {
                margin: 0; }
            b {
                font-size: 1rem;
                font-weight: 700; }
            h4 {
                font-size: .9rem;
                font-weight: 700; } }
        &__social {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 30px;
            align-items: center;

            @include tablet {
                padding: 0; }

            p {
                font-size: .8rem; }
            a {
                color: $promo-detail-primary;
                font-size: 1.2rem;
                transition: ease all .3s;

                &:hover {
                    transform: scale(1.1); }

                &:not(:last-child) {
                    padding-right: 15px; } } } }

    &__container {
        max-width: 1200px;
        margin: 0 auto;
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
            padding-bottom: 50px; }
        &__img {
            width: 100%;
            margin: 0 auto;
            max-width: 450px;
            border-radius: 10px;
            display: block;

            @include desktop {
                max-width: 670px; } }

        &__info {

            &__logo {
                height: 60px;
                width: 60px;
                border: 1px solid $promo-detail-circle;
                border-radius: 50%;
                margin: 30px 0;
                display: flex;
                justify-content: center;
                align-items: center;

                @include desktop {
                    margin-top: 0; } }

            &__title {
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 1.4rem;
                color: $promo-detail-primary; }

            &__paragraph {
                padding: 20px 0;
                font-size: .9rem; }

            &__expiry {
                display: flex;
                font-size: .9rem;
                &__icon {
                    padding-right: 15px; }
                &__until {
                    font-weight: bold;
                    line-height: .9rem; } }
            &__conditions {
                font-size: .7rem;
                color: $promo-detail-secondary-font;
                padding: 40px 0 50px 0; } } } }

.otras-promo {
    background: $promo-detail-bg;
    padding: 0 30px 50px;
    color: $promo-detail-primary;
    padding-bottom: 250px;

    &__title {
        font-size: 1.6rem;
        padding: 30px 0;
        max-width: 1200px;
        margin: 0 auto; }
    &__cards {
        display: grid;
        grid-gap: 20px;
        justify-items: center;
        max-width: 1200px;
        margin: 0 auto;

        @include tablet {
            grid-template-columns: repeat(2, 1fr); }
        @include desktop {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 40px; }
        @include fullhd {
            grid-template-columns: repeat(4, 1fr); } } }
