// .......................................................................... { forms styles } #

.input-group {
  width: 100%;
  position: relative;
  margin-bottom: 0.5rem;
  .form-label {
    text-align: left;
    font-size: 0.8rem;
    padding: .5rem 0;
    padding-left: 2rem;
    color: $cg1;
    width: 100%;
    display: block;
    margin: 0;
    font-weight: bold; }
  .gral-input {
    width: 100%;
    height: 2.5rem;
    padding: 0 1rem;
    border: 0;
    border-radius: 50px;
    // border-bottom: solid 1px $cp4
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    // transition: all 0.3s
    color: $cg2;
    font-size: 0.9rem;
    background: $cb;
    // box-shadow: 0px 3px 15px -1px rgba(0,0,0,0.1);
    // box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1)
    box-shadow: 0px 3px 10px 0px #f3f3f3;
    -webkit-appearance: none;

    &--border {
      border: 1px solid $cg2; }

    &__textarea {
      padding: 0.5rem;
      font-size: 0.8rem;
      height: 4rem;
      border: solid 1px $cp4;
      &:focus {
        border-width: 2px;
        &:focus {
          border: solid 2px $cp5; } } }
    &:focus {
      // border-bottom: solid 2px $cp5
      outline: none !important;
      box-shadow: none !important; }
    &.success {
      border-color: $success !important;
      border-width: 2px; }
    &.warning {
      border-color: $warning !important;
      border-width: 2px; }
    &.error {
      border-color: $error !important;
      border-width: 2px; } }
  .validation-icon {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.success {
      color: $success;
      right: 5px; }
    &.warning {
      color: $warning; }
    &.error {
      color: $error; } }
  .input-alert.error {
    width: 100%;
    display: block;
    // padding: 0.1rem 0
    padding-left: 2em;
    font-size: 0.8rem;
    &.success {
      color: $success; }
    &.warning {
      color: $warning; }
    &.error {
      color: $error; } } }

// Checkboxes personalizadso
.checkbox-custom-label {
  a {
    text-decoration: none;
    color: $cp1;
    margin-left: 0.3em;
    font-weight: bold; } }

.checkbox-custom:focus + .checkbox-custom-label, .radio-custom:focus + .radio-custom-label {
  outline: none !important; }

.checkbox-custom, .radio-custom {
  opacity: 0;
  position: absolute; }

.checkbox-custom, .checkbox-custom-label, .radio-custom, .radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: none;
  font-weight: 400;
  font-size: 0.7em;
  padding-top: 0.3em; }

.checkbox-custom-label, .radio-custom-label {
  position: relative; }

.checkbox-custom + .checkbox-custom-label:before, .radio-custom + .radio-custom-label:before {
  content: '';
  background: transparent;
  border: 1px solid $cp1;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  text-align: center;
  transition: all 0.3s;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center; }
.checkbox-custom {
  &:checked {
    & + .checkbox-custom-label {
      &::before {
        content: "\e938";
        font-family: 'icomoon' !important;
        background: $cp8;
        color: white !important;
        font-size: 0.8em; } } } }

::-moz-placeholder {
  color: $cg2;
  font-weight: 600; }

::-webkit-input-placeholder {
  color: $cg2;
  font-weight: 4 00; }

::-ms-input-placeholder {
  color: $cg2;
  font-weight: 600; }

::-moz-placeholder {
  color: $cg2;
  font-weight: 600; }


.cutom-recaptcha {
  position: relative; }

.ico-proteccion {
  position: absolute;
  width: 30px;
  top: 0;
  right: 0;
  display: inline-block;
  vertical-align: top; }





// .................. {  login }

.login {
  padding: 0;
  height: 650px;
  &__close {
    position: relative;
    background: $cp1;
    color: $cb;
    display: block;
    text-align: center;
    // width: 100%
    font-size: 0.8rem;
    padding: 0.6rem 2rem 0.6rem 1rem;
    // margin-left: auto

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    // +tablet
    //   border-top-left-radius: initial
    //   border-top-right-radius: initial


    @include md {
      border-top-left-radius: 0; }
    @include lg {
      width: 100px;
      position: absolute;
      right: 0;
      top: -38px;
      // border-top-left-radius: 0px
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.6rem 1rem 0.6rem 1rem;
      // border-top-left-radius: 0px
      border-top-left-radius: 20px; }

    .delete {
      position: absolute;
      right: 7px; } }

  &__ingresar,
  &__registro {
    padding-top: 0; }

  &__subtitle {
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0 0 2rem; }

  &__img {
    background: url('../images/perfil/home-login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    position: relative;
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    img {
      margin-top: 15px;
      margin-left: 15px; } }

  &__logo {
    position: absolute;
    top: 0;
    left: 0; }

  &__termino {
    padding: 1rem 0; }

  &__content {
    position: relative;
    background: $cb;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding-top: 40px;
    margin-top: 40px!important {

      // +tablet
      //   margin-top: 0!important
      //   border-top-right-radius: initial
      //   border-top-left-radius: initial


      // +desktop
 }      //   // margin-top: 0!important

    border-top-right-radius: 23px;
    border-top-left-radius: 23px;
    border-bottom-right-radius: 20px;
    padding-top: 40px;
    @include sm {
      margin-top: 30px !important; }
    @include md {
      margin-top: 0 !important; } }

  .m--black {
    width: 100%;
    @include md {
 } }      // width: 120px

  .m--facebook {
    min-width: 100%; }

  &__facebook {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #f1f0f0;

    @include md {
      padding-bottom: 2em;
      margin-bottom: 1em; } }

  &__form {
    padding: 1rem;
    @include md {
      // padding: 2rem 4em
 } }      // padding: 0rem 2rem


  &__legal {
    padding: 1rem 0px;
    text-align: center;
    font-weight: 900;
    @include md {
      padding: 2rem 0px; }
    a {
      color: $cp1;
      font-size: 0.8rem; } }

  &__buttons {
    padding: 2rem 0; }

  &__recuperar {
    height: auto;
    min-height: 500px; }

  .button__prmimary {
    margin-bottom: 2rem; }

  &__registro {
 } }    // padding-top: 40px

// .................. {  tabs }

.tabs {
  &__link {
    font-weight: bold;
    font-size: 1rem;
    color: $cg2;
    width: 50%;
    background: $cp9;
    padding: .5rem 1rem;
    text-align: center;
    cursor: pointer;

    &.is-active,
    &:hover {
      background: $cb;
      color: $cp1 !important;
      border-bottom-color: $cb !important; } } }


[include*="form-input-select()"] select:invalid {
    color: #C7C7C7; }

[include*="form-input-select()"] select {
    border: 3px solid #E6E6E6;
    border-radius: 0;
    font-weight: 400;
    color: inherit;
    padding: 11px 15px;
    line-height: normal;
    transition: border-color 0.2s ease, outline 0.2s ease; }

[include*="form-input-select()"] select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    border: 1px solid transparent;
    font-size: 16px;
    outline: none; }
