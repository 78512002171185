// .......................................................................... { inner header styles } #

.inner-header {
  position: relative;
  background-color: $cp2;
  height: 16rem;
  // padding: 0 1rem
  padding: 1rem;
  @include md {
    height: 15.7rem;
 }    // height: 11.7rem

  &.m--subinner {
    height: 4rem;
    @include sm {
      height: 15.7rem; } }

  .header-interactions {
    padding: 1rem 0;
    margin-bottom: 1rem;
    @include md {
      padding: 3rem 2.5rem 0; }
    @include rwd(1088) {
      padding: 3rem 0 1rem 0;
      border-bottom: solid 1px $cp3; }
    h2 {
        width: 100%;
        font-weight: 700;
        font-size: 1.5rem;
        color: $cp5;
        margin-bottom: 1rem;
        text-align: center; }
    .column.is-three-fifths {
      width: 46%;
 }      // width: 60%
    .m--left {
      text-align: left; } } }
