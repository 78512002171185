$noticias-detail-bg: #fafafa;
$noticias-detail-primary: #00B3A2;
$noticias-detail-hover: #00d4c0;
$noticias-detail-circle: #EAEBEC;
$noticias-detail-primary-font: #292C33;
$noticias-detail-secondary-font: #B3B3B3;

.noticias-detail {
    background: $noticias-detail-bg;
    padding: 0 30px;

    &__header {
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 50px;

        @media (max-width: 1200px) {
            margin-top: 50px; }

        @media (max-width: 992px) {
            margin-top: 0; }

        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 0 50px 0; }

        &__button {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            max-height: 44px;
            background: $noticias-detail-primary !important;
            &:hover {
                background: $noticias-detail-hover !important; }

            @include tablet {
                margin: 0; }
            b {
                font-size: 1rem;
                font-weight: 700; }
            h4 {
                font-size: .9rem;
                font-weight: 700; } }
        &__social {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 30px;
            align-items: center;

            @include tablet {
                padding: 0; }

            @include desktop {
                flex-direction: row;
                align-items: center; }
            p {
                font-size: .8rem;
                padding-bottom: 10px;

                @include desktop {
                    padding-right: 15px;
                    padding-bottom: 3px; } }
            a {
                color: $noticias-detail-primary;
                font-size: 1.2rem;
                transition: ease all .3s;

                &:hover {
                    transform: scale(1.1); }

                &:not(:last-child) {
                    padding-right: 15px; } } } }

    &__container {
        max-width: 1200px;
        margin: 0 auto;
        @include desktop {
            padding-bottom: 50px; }
        &__title {
            font-size: 1.5rem;
            line-height: 1.9rem;
            color: $noticias-detail-primary;
            font-weight: bold;
            text-align: center;
            padding-bottom: 50px;

            @include desktop {
                padding: 0 200px 50px 200px; } }
        &__img {
            width: 100%;
            margin: 0 auto;
            max-width: 450px;
            display: block;
            position: relative;
            padding-bottom: 20px;
            @include desktop {
                display: flex;
                width: 1000px;
                margin: none;
                justify-content: center;
                max-width: initial; }

            &__image {
                border-radius: 10px;
                display: none;

                @include desktop {
                    display: block; }

                &--mobile {
                    display: block;

                    @include desktop {
                        display: none; } }
                @include desktop {
                    width: 100%; }

                &.is--block {
                    display: block !important; } }
            &__wave {
                background: url(../images/noticias/wave-noticias.svg) no-repeat 100% 0;
                background-size: contain;
                height: auto;
                position: absolute;
                top: 0px;
                right: 0px;
                border-top-right-radius: 10px;
                text-align: center;
                padding: 5px 10px 16px 40px;
                color: $noticias-detail-primary;

                @include tablet {
                    padding: 20px 50px 60px 120px; }

                &__day {
                    font-size: 1rem;
                    @include tablet {
                        font-size: 2rem; } }
                &__month {
                    font-size: .5rem;
                    @include tablet {
                        font-size: 1rem; } } } }
        &__info {
            @include desktop {
                padding: 0 300px; }
            &__paragraph {
                padding: 20px 0;
                font-size: .8rem;
                p {
                    padding-bottom: 20px; }
                &__bold {
                    font-weight: bold;
                    color: $noticias-detail-primary-font; }
                &__normal {
                    color: $noticias-detail-primary-font; } }
            &__video {
                iframe {
                    border-radius: 5px; } } } } }
.otros-noticias {
    background: $noticias-detail-bg;
    padding: 0 30px 50px;
    color: $noticias-detail-primary;
    padding-bottom: 250px;

    &__title {
        font-size: 1.6rem;
        padding: 30px 0;
        max-width: 1200px;
        margin: 0 auto; }
    &__cards {
        display: grid;
        grid-gap: 20px;
        justify-items: center;
        max-width: 1200px;
        margin: 0 auto;

        @include tablet {
            grid-template-columns: repeat(2, 1fr); }
        @include desktop {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 40px; }
        @include fullhd {
            grid-template-columns: repeat(4, 1fr); } } }
