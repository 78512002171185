.alert {
    border-radius: 10px;
    padding: 0 1.4em;
    font-weight: bold;
    font-size: 14px;
    &__container {
        padding: 1em 0;
        display: flex;
        justify-content: center;
        align-items: center; }
    &--black {
        background-color: $cg1;
        color: $cb; }
    &__icon {
        padding-right: 1em;
        cursor: pointer;
        b {
            font-size: 2em;
            @include lg {
                font-size: 1em; } } }
    &__message {} }

