$cartelera-card-bg: #fff;
$cartelera-font-color: #333333;
$cartelera-primary: #E62E61;
$cartelera-hover: #ff2965;
$cartelera-box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.1);

// ............................................ { cartelera } #

// Events list styles
.cartelera {
	padding-bottom: 250px;

	&__padding {
		padding: 0 30px;
		max-width: 1200px;
		width: 100%;
		margin: 0 auto; }

	&__title {
		padding: 100px 20px 200px 20px;
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: $nosotros-font-color-neutral;

		@include desktop {
			padding: 80px 20px; } }

	&__principal {
		position: relative;
		top: -100px;
		z-index: 100;
		margin: 0 auto;
		padding-bottom: 50px;
		background: $cartelera-card-bg;
		-webkit-box-shadow: $cartelera-box-shadow;
		-moz-box-shadow: $cartelera-box-shadow;
		box-shadow: $cartelera-box-shadow;
		border-radius: 10px;
		margin-bottom: -100px;

		@include tablet {
			top: -20px;
			display: grid;
			grid-template-columns: 1fr 2.5fr;
			grid-gap: 10px;
			align-items: center;
			margin-bottom: 30px;
			padding-bottom: 0; }
		&__img {
			border-radius: 10px;
			width: 100%; }

		&__content {
			padding: 0 15px;
			&__title {
				padding: 20px 0;
				font-size: 1.6rem;
				font-weight: bold;
				line-height: 2rem;
				color: $cartelera-primary; }
			&__info {
        @include desktop {
        	display: flex; }
        &__paragraph {
          color: $cartelera-font-color;
          font-size: .9rem;
          flex-basis: 60%; }
        &__information {
          font-size: .9rem;
          padding-top: 20px;
          @include desktop {
            padding-top: 0;
            padding-left: 20px; }
          &__box {

            &:not(:last-child) {
              padding-bottom: 20px; }
            &__title {
              color: $cartelera-font-color;
              font-weight: bold; } } } }

			&__button {
				button {
					background: $cartelera-primary !important;
					max-width: 315px;
					width: 100%;
					margin: 30px 0 0 0;

					&:hover {
						background: $cartelera-hover !important; }

					@include tablet {
						max-width: 190px; } } } }
		&__wave {
			background: url(../images/cartelera/wave-cartelera.svg) no-repeat 100% 0;
			background-size: contain;
			height: auto;
			position: absolute;
			top: -1px;
			right: -1px;
			border-top-right-radius: 10px;
			text-align: center;
			padding: 15px 30px 45px 90px;
			color: $cartelera-card-bg;

			@include tablet {
				padding: 20px 60px 60px 120px; }

			&__years {
				font-size: 1.6rem;
				@include tablet {
					font-size: 2rem; } }
			&__year {
				font-size: .8rem;
				@include tablet {
					font-size: 1rem; } } } }

	&__movies {
		padding: 30px 0;

		&__title {
			font-size: 1.6rem;
			color: $cartelera-primary;
			padding-bottom: 20px;
			text-transform: uppercase; }

		&__container {
			display: grid;
			grid-gap: 20px;
			justify-items: center;
			justify-content: center;
			padding: 20px 0;
			max-width: 1200px;
			margin: 0 auto;

			@include tablet {
				grid-template-columns: repeat(3, 1fr); }
			@include desktop {
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 40px; }
			@include fullhd {
				grid-gap: 40px; } } }

	&__show {
		button {
			background: $cartelera-primary !important;
			max-width: 315px;
			width: 100%;
			margin: 0 auto;
			&:hover {
				background: $cartelera-hover !important; } }

		&--less {
			display: none;
			button {
				&:hover {
					background: $cartelera-hover !important; } } } } }

