$promo-card-box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);
$promo-card-primary: #F06031;
$promo-card-hover: #ff6d3d;
$promo-card-alternative: #FAFAFA;
$promo-card-bg: #fff;

$slider-main-orange: #F06031;

.promo-card {
    display: none;
    background: $promo-card-bg;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: $promo-card-box-shadow;
    -moz-box-shadow: $promo-card-box-shadow;
    box-shadow: $promo-card-box-shadow;
    -webkit-appearance: none;
    position: relative;
    transition: ease all .3s;
    max-width: 240px;
    height: 470px;

    @include desktop {
        max-width: 315px;
        height: 450px; }

    &:hover {
        transform: scale(1.02); }

    &:nth-child(-n+6) {
        display: block; }

    @include fullhd {
        &:nth-child(-n+16) {
            display: block; } }


    &__img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: auto;
        width: 100%;
        object-fit: cover; }

    &__title {
        padding: 20px;
        font-size: .9rem;
        font-weight: bold;
        color: $promo-card-primary;
        &:hover {
            color: $promo-card-hover !important; } }
    &__subtitle {
        padding: 0 20px;
        font-size: .9rem; }
    &__expiry {
        display: flex;
        justify-content: center;
        padding: 10px;
        border-top: 1px solid $promo-card-alternative;
        font-weight: 300;
        font-size: .8rem;
        text-align: center;
        margin-top: 10px;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0; } }

.slider-cards--orange {
    .slick-arrow {
        &::before {
            color: $slider-main-orange; }
        &:hover {
            background: $slider-main-orange !important;
            &::before {}
            color: $slider-main-bg !important; } }
    .slick-dots {
        bottom: -25px;
        li {
            button {
                &::before {
                    color: $promo-card-alternative; } } }
        li.slick-active {
            button {
                &::before {
                    color: $slider-main-orange; } } } } }

.slider-header-home {
    padding: 0 30px;

    @include desktop {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px; }

    &--orange {
        color: $slider-main-orange; }

    &__title {
        font-size: 1.6rem; }

    &__button {

        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        justify-items: right;
        margin: 30px 0;
        max-width: 315px;

        &--orange {
            background: $promo-card-alternative !important;
            color: $slider-main-orange !important;
            &:hover {
                color: $promo-card-hover !important; } }

        @include desktop {
            margin: 0; }



        b {
            font-weight: bold;
            transform: rotate(180deg); }
        h4 {
            font-weight: bold; } } }
