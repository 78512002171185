$modal-z: 1300 !default;

$modal-background-background-color: rgba($black, 0.6) !default;
$modal-background-image: #dadada !default;
$modal-link-color: #903392;

$modal-content-width: 940px !default;
$modal-content-margin-mobile: 20px !default;
$modal-content-spacing-mobile: 50px !default;
$modal-content-spacing-tablet: 40px !default;

$modal-close-dimensions: 40px !default;
$modal-close-right: 20px !default;
$modal-close-top: 20px !default;

$modal-card-spacing: 40px !default;

$modal-card-head-background-color: $background !default;
$modal-card-head-border-bottom: 1px solid $border !default;
$modal-card-head-padding: 20px !default;
$modal-card-head-radius: $radius-large !default;

$modal-card-title-color: $text-strong !default;
$modal-card-title-line-height: 1 !default;
$modal-card-title-size: $size-4 !default;

$modal-card-foot-radius: $radius-large !default;
$modal-card-foot-border-top: 1px solid $border !default;

$modal-card-body-background-color: $white !default;
$modal-card-body-padding: 20px !default;

.modal-container {
  padding-top: 100px;

  @include desktop {
    padding-top: 0; } }

.modal {
  @include overlay;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: $modal-z;
  // Modifiers
  &.is-active {
    display: flex; }

  &__content {
    box-shadow: 0px -5px 10px -1px rgba(0,0,0,0.24);
    -webkit-appearance: none;
    background-color: #fbfbfb;
    width: 100%;
    height: 100vh;
    top: 80px;
    left: 0;
    position: fixed;
    overflow: scroll;

    @include desktop {
      border-radius: 10px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 60%;
      height: 80%; } } }
.content {
  border-radius: 20px; }
.button__inner__close {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
    font-size: 20px;
    font-weight: 300;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    box-shadow: 0px 2px 10px -1px rgba(0,0,0,0.1);
    -webkit-appearance: none;
    cursor: pointer;
    text-align: center;
    &:focus {
      outline: none;
      border: none; }

    &:hover {
      background-color: #fafafa;
      color: #cfcfcf; } } }

.container__button__inner__close {
  position: absolute;
  right: 10px; }


.modal-background {
  @include overlay;
  background-color: $modal-background-background-color;
  position: fixed;
  top: 80px;
  left: 0;

  &--app {
    top: 0; }


  @include desktop {
    top: 0; } }

.modal-content,
.modal-card {
  // margin: 1em $modal-content-margin-mobile
  // max-height: calc(100vh - #{$modal-content-spacing-mobile})
  max-height: 100vh;
  overflow: auto;
  position: relative;
  width: 100%;
  // Responsiveness
  @include tablet {
    margin: 0 auto;
    // max-height: calc(100vh - #{$modal-content-spacing-tablet})
    width: $modal-content-width; } }

.modal-close {
  @include delete;
  background: none;
  height: $modal-close-dimensions;
  position: fixed;
  right: $modal-close-right;
  top: $modal-close-top;
  width: $modal-close-dimensions; }

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - #{$modal-card-spacing});
  overflow: hidden; }

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background-color: $modal-card-head-background-color;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: $modal-card-head-padding;
  position: relative; }

.modal-card-head {
  border-bottom: $modal-card-head-border-bottom;
  border-top-left-radius: $modal-card-head-radius;
  border-top-right-radius: $modal-card-head-radius; }

.modal-card-title {
  color: $modal-card-title-color;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: $modal-card-title-size;
  line-height: $modal-card-title-line-height; }

.modal-card-foot {
  border-bottom-left-radius: $modal-card-foot-radius;
  border-bottom-right-radius: $modal-card-foot-radius;
  border-top: $modal-card-foot-border-top;
  .button {
    &:not(:last-child) {
      margin-right: 10px; } } }

.modal-card-body {
  @include overflow-touch;
  background-color: $modal-card-body-background-color;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: $modal-card-body-padding; }



// MODAL ADD INVOICE

#formAddIvoice {
 }  // overflow: auto
.add__invoice {
  &__container {
    // height: 380px
    margin: 0 auto;
    margin-top: 3em;
    margin-bottom: 200px;
    padding-top: 2em;
    padding-bottom: 2em;
    // border: 1px dashed #d8d8d8
    // border-radius: 10px
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='silver' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    // padding: 20px;
    // display: inline-block;

    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    @include mobile {
 }      // overflow-y: auto
    @include desktop {
      overflow-y: none;
      margin-bottom: 3em; }

    &__success {
      padding: 5em 15em;
      text-align: center;
      max-width: 380px;
      margin: 0 auto; } }

  &__icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: .6em auto;
    background-color: #EEEFF0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    img.check-icon {
      width: 40px; }
    .icon {
      font-size: 4em;
      color: white; }
    img {
      max-width: 82px; } }

  &__title {
    font-size: 14px;
    margin: 1em auto;
    width: 80%;
    font-weight: bold;
    text-align: center;

    @include desktop {
      display: none; }

    &--desktop {
      display: none;

      @include desktop {
        display: inline-block; } } }
  &__paragraph {
    font-size: 12px;
    margin: 1em auto;
    width: 70%;
    margin-bottom: 2em;
    text-align: center;
    display: none;

    @include desktop {
      display: inline-block; } }

  &__limiter {
    margin: 0 auto;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // max-width: 50%

    @include desktop {
      padding: 20px 200px;
      margin-bottom: 20px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='silver' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      text-align: center; } }

  &__info__message {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #cccccc;
    padding-top: 100px;
    padding-bottom: 50px;
    @include mobile {
      margin-bottom: 30px; }
    @include desktop {
      margin-bottom: 10px;
      padding-bottom: 0;
      padding-top: 0; }

    &:not(:last-child) {
      margin-bottom: 1.5em; }

    &--red {
      color: red; } }

  &__input__file {
    &__continue {
      margin-top: 10px; }
 }    // width: 80%


  &__image {
    background: $modal-background-image;
    padding: 0!important;

    @include desktop {
      display: flex!important;
      align-items: center; }

    img {
      max-width: 400px;
      width: 100%;
      display: block;
      margin: 0 auto;

      @include desktop {
        border-radius: 10px; } } }

  &__form {
    // min-height: 700px
    width: 100%;
    padding: 2em 3em !important;
    // max-height: 80vh
    // overflow: auto
    // padding-top: 5% !important
    // form
    //   max-height: 80vh
    @include mobile {
      margin-bottom: 300px; }
    @include desktop {
      margin-bottom: 10px; }


    &__title {
      font-weight: bold;
      color: #3a3a3a;
      padding-bottom: 10px;
      text-align: center; }
    &__button__submit {
      padding-top: 2em; }

    &__button__cancel {
      a {
        display: block;
        font-weight: bold;
        font-size: 12px;
        padding-top: 2em;
        color: #903392;
        text-align: center; } } } }



.add__invoice__image__dragged {
  margin-bottom: 30px;
  img {
    border-radius: 10px;
    max-width: 234px;
    max-height: 174px; } }

.examinar-bottom {
  font-size: .75rem!important; }

.terminos-condiciones {
  padding: 20px 10px 10px 10px;
  display: flex;
  align-items: center;

  &__anchor {
    padding-left: 10px;
    font-size: .75rem;
    transition: all ease .3s;

    &:hover {
      color: $modal-link-color; } } }






// ---------------------------------- modal confirm
.modal__confirm {
  &__content {
    width: 300px;
    height: 200px;
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px; }
  &__title,&__msg {
    text-align: center;
    span {
      font-size: 15px;
      font-weight: bold; } } }

