// ...................................................{ shearch } #

.search-box {
  // max-width: 480px
  // width: 90%
  // margin: 20px auto
  margin: 1em 0;
  // @include md
  //   margin: 1em 0

  .colmun {
    justify-content: right; }

  &__content {
    position: relative; }

  &__label {
    font-size: 0.8rem;
    color: $cp4;
    position: absolute;
    top: -20px;
    left: 10px; }



  &__input {
    border-style: none;
    background: $cb;
    box-shadow: none;
    color: $cg1;
    font-weight: normal;
    // position: relative
    z-index: 2;
    padding: 12px 20px;
    // transition: all 0.5s ease
    border-radius: 50px;
    &:focus {
      box-shadow: none;
 } }      // border-radius: 10px

  &__button {
    background: none;
    border: 0;
    border-style: none;
    box-shadow: none;
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 15px;
    z-index: 3;
    font-size: 1.3rem; }

  &--marketplace {
    .search-box__input {
      background-color : #FF6D3D;
      color: white; }
    .search-box__input::placeholder {
      color: white; }
    .icon-search:before {
      color: white; } } }



.al-mapa {
  .search-box {
    &__label {
      display: none; }
    &__input {
      color: $cn;
      background-color: $cp7;
      &:focus {
        border: none; } }
    &__button {
      top: 6px;
      font-size: 1.3rem; } } }

