$titan-footer-background: #903392!default;
$titan-footer-background-social-media: #B337AD!default;

$titan-footer-font-color: #fff!default;
$titan-footer-font-color-nav: #DDDFE6!default;
$titan-footer-font-color-title: #E0A1DD!default;
$titan-footer-font-hover: #cacaca!default;

.titan-footer {
    background: $titan-footer-background;
    padding: 50px 20px 50px 20px;
    color: $titan-footer-font-color;
    position: relative;
    z-index: 100;
    font-size: .9rem;
    margin-top: 200px;

    @include desktop {
        padding: 20px 30px 20px 30px; }


    &::before {
        content: "";
        bottom: 99.9%;
        right: 0;
        position: absolute;
        z-index: -1;
        width: 101%;
        height: 100px;
        background: url(../images/footer/wave-footer.svg) no-repeat center 100%;
        background-size: contain; }
    @include desktop {
        &::before {
            content: "";
            bottom: 99.7%;
            right: 0;
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 250px;
            background: url(../images/footer/wave-footer.svg) no-repeat 0 100%;
            background-size: cover; } }
    &--home {
        padding-top: 50px;
        &::before {
            display: none; } }
    &__branch {
        margin: 0 auto;
        @include desktop {
            margin: 0; }
        img {
            width: 100%;
            max-width: 200px;
            display: block;
            margin: 0 auto;

            @include desktop {
                margin: 0;
                padding-bottom: 30px; } } }

    &__container {

        @include desktop {
            display: flex;
            justify-content: space-between;
            max-width: 1200px;
            margin: 0 auto; }

        &__info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 0.813;
            text-align: center;

            @include desktop {
                text-align: left;
                align-items: flex-start;
                justify-content: initial; }

            &__address {
                padding-top: 20px;

                @include desktop {
                    padding-top: initial; } }

            &__email {
                padding-top: 20px;

                a {
                    transition: ease all .3s;

                    &:hover {
                        color: $titan-footer-font-hover; } } }

            &__phone {
                padding-top: 20px;
                padding-bottom: 30px;

                a {
                    transition: ease all .3s;

                    &:hover {
                        color: $titan-footer-font-hover; } } } }

        &__social {
            display: flex;
            padding-bottom: 20px;
            justify-content: center;

            @include desktop {
                flex-direction: column;
                justify-content: initial; }

            &__title {
                display: none;
                color: $titan-footer-font-color-title;
                font-weight: 600;

                @include desktop {
                    display: flex;
                    padding-bottom: 30px; } }

            &__content {
                display: flex;

                &__container {
                    background: $titan-footer-background-social-media;
                    border-radius: 50%;
                    height: 34px;
                    width: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $titan-footer-font-color;
                    transition: all ease .3s;

                    &:hover {
                        transform: scale(1.05); }

                    &:not(:last-child) {
                        margin-right: 5px; } } } }

        &__newslatter {
            padding: 20px 0;
            text-align: center;
            color: $titan-footer-font-color-title;
            font-weight: 600;
            line-height: 1.3rem;

            @include desktop {
                padding: initial;
                text-align: left; }

            h5 {
                font-size: 0.813; }

            &__field {
                padding-top: 20px;
                margin: 0 auto;
                max-width: 400px;

                @include desktop {
                    padding-top: 30px; } }

            &__social {
                display: none;

                @include desktop {
                    display: flex;
                    padding-bottom: 30px;

                    &__container {
                        background: $titan-footer-background-social-media;
                        border-radius: 50%;
                        height: 34px;
                        width: 34px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $titan-footer-font-color;
                        transition: all ease .3s;

                        &:hover {
                            transform: scale(1.05); }

                        &:not(:last-child) {
                            margin-right: 5px; } } } } }

        &__nav {
            display: flex;
            justify-content: space-between;
            text-align: left;
            font-size: .7rem;

            &__row {
                flex-basis: 50%;
                &:not(:last-child) {
                    padding-right: 10px;

                    @include desktop {
                        padding-right: 50px; }

                    @include widescreen {
                        padding-right: 90px; } }

                &__title {
                    color: $titan-footer-font-color-title;
                    font-weight: 600;
                    font-size: .9rem;
                    @include desktop {
                        text-align: left; } }

                ul {
                    padding: 20px 0;

                    li {
                        @include desktop {
                            text-align: left; }

                        &:not(last-child) {
                            padding-bottom: 8px; }

                        a {
                            color: $titan-footer-font-color-nav;
                            font-size: 0.9rem;
                            transition: all ease .3s;

                            &:hover {
                                color: $titan-footer-font-hover; } } } } } } }

    &__footer {
        padding: 20px 0 0 0;

        @include desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 50px 0 0 0;
            max-width: 1200px;
            margin: 0 auto; }

        &__copyright {
            text-align: center;

            p {
                font-size: 0.75rem;
                color: $titan-footer-font-color-title; } }

        &__code-labs {
            padding-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include desktop {
                padding-top: initial; }

            p {
                font-size: 0.625rem;
                color: $titan-footer-font-color-title;
                padding-right: 5px; } } } }

.box-order {

    @include desktop {
        &:first-child {
            order: 1; }

        &:nth-child(2) {
            order: 4; }

        &:nth-child(3) {
            order: 3; } } }
