.button {
  border-style: none;
  border-radius: 50px;
  box-shadow: none;
  width: 100%;
  font-size: 16px;
  // line-height: 2rem
  // height: 40px
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  transition: all 0.2s ease-out;
  box-shadow: 0px 2px 10px -1px rgba(0,0,0,0.1);

  &--catalogo {
    color: $cp5;
    font-weight: bold;
    max-width: 180px;
    font-size: 15px; }

  &--shadow__none {
      box-shadow: none; }

  &--sm.button {
    max-width: 140px;
    font-size: 12px;
    color: $cp1;
    font-weight: bold;

 }    // height: ;


  &--background__gray {
    background-color: $cg4; }

  &__primary {
    background: $cp1 !important;
    color: $cb;
    font-weight: bold;
    padding-top: .9em;
    padding-bottom: .9em;
    font-size: 12px;
    min-width: 150px;

    // border-radius: 8px
    &:hover {
      background: $cp12;
      color: $cb !important; } }



  &__default {
    color: $cp1;
    // border-radius: 8px

    &:hover {
      background: $cp1 !important;
      color: $cb !important; } }

  &.m--black {
    background: $cn;
    // width: 120px

    &:hover {
      color: $cb; } }

  &.m--facebook {
      background: $facebook;
      color: $cb;
      max-width: 300px;
      width: 100%;
      position: relative;
      font-size: 12px;
      padding-top: .8em;
      padding-bottom: .8em;
      font-weight: bold;
      &:hover {
        color: $cb; }

      .icons {
        font-size: 1.3rem;
        margin-right: .5rem;
        top: 8px;
        left: 10px; } }

  &__avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    position: relative;
    top: 0;
    left: -12px; }

  &__bordered {
    border: 1px solid $cp1;
    color: $cp1;
    font-weight: bold; }

  &__text {
    &--orange {
      color: $cp5; }
    &--green {
      color: $cp4; } }

  &.m--whatsapp {
    position: fixed;
    bottom: 10px;
    right: 15px;
    width: auto;
    height: 40px;
    color: transparent;
    background: transparent;
    border-radius: 8px;
    z-index: 500;
    font-size: 0;
    min-width: 45px;

    &:hover {
      color: #CCFFF7; }
    @include sm {
      font-size: 14px;
      color: white;
      background: #1EBEA5;
      font-weight: bold;
      height: 44px;
      padding-left: 64px;
      padding-right: 44px; }

    img {
      position: absolute;
      width: 44px;
      left: 10px;
      @include sm {
        top: -10px; } } }

  &__black-button {
    background-color: $cp5 !important; }


  &--light {
    margin-top: 15px;
    background-color: rgba(255,255,255,.2);
    color: white;
    font-weight: bold;
    padding: .7em;
    font-size: 15px; }

  &--agregar__fatcura {
    color: $cp1;
    font-weight: bold;
    padding: .7em;
    font-size: 15px; }

  .back-button-icon {
    margin: 0.5rem;
    transform: rotate(90deg) !important;
    display: block;
    font-size: 1rem; }

  &:hover {
    color: $cn; }

  &__bg--green {
    background-color: $cp4 !important; } }

.medium {
  @include sm {
    max-width: none; }
  @include md {
    display: block;
    min-width: 220px;
    max-width: 230px;
    // margin: 0 auto
    font-weight: bold; } }

