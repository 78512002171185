::focus {
  outline: none; }
// ........................................{ box date } #
.date {
  position: absolute;
  text-align: center;
  color: $cn;
  &__day {
    font-size: 0.9rem;
    font-weight: 800;
    display: block;
    margin-bottom: -5px; }
  &__month {
    font-size: 0.7rem;
    font-weight: 400;
    display: block;
    font-style: normal;
    color: $cp4; } }

// ........................................{ video} #

.video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin: 20px 0 0;
  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

.ext_up {
  padding: 20px;
  margin-top: -100px; }

// ........................................{ video} #
.loading {
  display: block;
  margin: 0 auto; }
