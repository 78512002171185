$navbar-background-color: $white !default;
$navbar-box-shadow-size: 0 2px 0 0 !default;
$navbar-box-shadow-color: $background !default;
$navbar-height: 3.25rem !default;
$navbar-padding-vertical: 1rem !default;
$navbar-padding-horizontal: 2rem !default;
$navbar-z: 30 !default;
$navbar-fixed-z: 30 !default;

$navbar-item-color: $grey-dark !default;
$navbar-item-hover-color: $link !default;
$navbar-item-hover-background-color: $white-bis !default;
$navbar-item-active-color: $black !default;
$navbar-item-active-background-color: transparent !default;
$navbar-item-img-max-height: 1.75rem !default;

$navbar-tab-hover-background-color: transparent !default;
$navbar-tab-hover-border-bottom-color: $link !default;
$navbar-tab-active-color: $link !default;
$navbar-tab-active-background-color: transparent !default;
$navbar-tab-active-border-bottom-color: $link !default;
$navbar-tab-active-border-bottom-style: solid !default;
$navbar-tab-active-border-bottom-width: 3px !default;

$navbar-dropdown-background-color: $white !default;
$navbar-dropdown-border-top: 2px solid $border !default;
$navbar-dropdown-offset: -4px !default;
$navbar-dropdown-arrow: $link !default;
$navbar-dropdown-radius: $radius-large !default;
$navbar-dropdown-z: 20 !default;

$navbar-dropdown-boxed-radius: $radius-large !default;
$navbar-dropdown-boxed-shadow: 0 8px 8px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;

$navbar-dropdown-item-hover-color: $black !default;
$navbar-dropdown-item-hover-background-color: $background !default;
$navbar-dropdown-item-active-color: $link !default;
$navbar-dropdown-item-active-background-color: $background !default;

$navbar-divider-background-color: $background !default;
$navbar-divider-height: 2px !default;

$navbar-bottom-box-shadow-size: 0 -2px 0 0 !default;

$navbar-marketplace-background: #FAFAFA;
$navbar-marketplace-color: #FF6D3D;

@mixin navbar-fixed {
  left: 0;
  position: fixed;
  right: 0;
  z-index: $navbar-fixed-z; }

.navbar {
  background-color: $navbar-background-color;
  min-height: $navbar-height;
  position: relative;
  z-index: $navbar-z;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $color-invert;
      .navbar-brand {
        & > .navbar-item,
        .navbar-link {
          color: $color-invert; }
        & > a.navbar-item,
        .navbar-link {
          &:hover,
          &.is-active {
            background-color: darken($color, 5%);
            color: $color-invert; } }
        .navbar-link {
          &::after {
            border-color: $color-invert; } } }
      @include desktop {
        .navbar-start,
        .navbar-end {
          & > .navbar-item,
          .navbar-link {
            color: $color-invert; }
          & > a.navbar-item,
          .navbar-link {
            &:hover,
            &.is-active {
              background-color: darken($color, 5%);
              color: $color-invert; } }
          .navbar-link {
            &::after {
              border-color: $color-invert; } } }
        .navbar-item.has-dropdown:hover .navbar-link,
        .navbar-item.has-dropdown.is-active .navbar-link {
          background-color: darken($color, 5%);
          color: $color-invert; }
        .navbar-dropdown {
          a.navbar-item {
            &.is-active {
              background-color: $color;
              color: $color-invert; } } } } } }
  & > .container {
    align-items: stretch;
    display: flex;
    min-height: $navbar-height;
    width: 100%; }
  &.has-shadow {
    box-shadow: $navbar-box-shadow-size $navbar-box-shadow-color; }
  &.is-fixed-bottom,
  &.is-fixed-top {
    @include navbar-fixed; }
  &.is-fixed-bottom {
    bottom: 0;
    &.has-shadow {
      box-shadow: $navbar-bottom-box-shadow-size $navbar-box-shadow-color; } }
  &.is-fixed-top {
    top: 0; } }

html,
body {
  &.has-navbar-fixed-top {
    padding-top: $navbar-height; }
  &.has-navbar-fixed-bottom {
    padding-bottom: $navbar-height; } }

.navbar-brand,
.navbar-tabs {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: $navbar-height;
  margin-right: 0; }

.navbar-brand {
  a.navbar-item {
    &:hover {
      background-color: transparent; } } }

.navbar-tabs {
  @include overflow-touch;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden; }

.navbar-burger {
  width: 30px;

  @include hamburger($navbar-height);
 }  // margin-left: auto

.navbar-menu {
  // display: none
  @media (min-width: 992px) {
    display: grid !important;
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    justify-content: initial !important; } }

.navbar-item,
.navbar-link {
  color: $navbar-item-color;
  display: block;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;
  .icon {
    &:only-child {
      margin-left: -0.25rem;
      margin-right: -0.25rem; } } }

a.navbar-item,
.navbar-link {
  cursor: pointer;
  &:hover,
  &.is-active {
    // background-color: none !important
 } }    // color: $cp1

.navbar-item {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  img {
    max-height: $navbar-item-img-max-height; }
  &__marketplace {
    background:#F0F0F0 {}
    margin-top: 10px;
    max-width: 170px;
    max-height: 40px;
    width: 100%;
    padding: 10px 20px;
    text-transform: capitalize;
    font-size: 0.75rem;
    font-weight: 600;
    display: flex;
    letter-spacing: 0.04rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    // display: none
    // margin-left: 20px
    color: #FF6D3D;
    border-radius: 20px;
    transition: ease all .2s;

    &:hover {
      background: #F4F4F4; }

    &--mobile {

      @include desktop {
        display: none !important; } }
    &__icon {
      max-width: 20px;
      padding-right: 5px; }
    b {
      padding-left: 5px;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      transform: rotate(-90deg)translateX(-3px); }
    @media (min-width: 1199px) {
      background: $navbar-marketplace-background;
      color: $navbar-marketplace-color !important;
      border-radius: 22px;
      display: flex; } }

  &.has-dropdown {
    padding: 0; }
  &.is-expanded {
    flex-grow: 1;
    flex-shrink: 1; }
  &.is-tab {
    border-bottom: 1px solid transparent;
    min-height: $navbar-height;
    padding-bottom: calc(0.5rem - 1px);
    &:hover {
      background-color: $navbar-tab-hover-background-color;
      border-bottom-color: $navbar-tab-hover-border-bottom-color; }
    &.is-active {
      background-color: $navbar-tab-active-background-color;
      border-bottom-color: $navbar-tab-active-border-bottom-color;
      border-bottom-style: $navbar-tab-active-border-bottom-style;
      border-bottom-width: $navbar-tab-active-border-bottom-width;
      // color: $navbar-tab-active-color
      padding-bottom: calc(0.5rem - #{$navbar-tab-active-border-bottom-width}); } } }

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1; }

.navbar-link {
  padding-right: 2.5em;
  // padding-bottom: 24px
  @media (min-width: 992px) {
    padding-bottom: 24px; }
  &::after {
    // +arrow($navbar-dropdown-arrow)
    // margin-top: -0.375em
    // right: 1.125em
    content: "";
    top: -2px;
    right: -10px;
    position: relative;
    width: 10px;
    height: 10px;
    background: url(../images/dropdown.svg) no-repeat center 100%;
    background-size: contain; } }

.navbar-dropdown {
  display: none;
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
  padding-top: 0rem;
  @media (min-width: 992px) {
    padding-bottom: 1.7rem;
    padding-top: 1.3rem; }
  .navbar-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    text-align: left; } }

.navbar-divider {
  background-color: $navbar-divider-background-color;
  border: none;
  display: none;
  height: $navbar-divider-height;
  margin: 0.5rem 0; }

@include touch {
  .navbar > .container {
    display: block; }
  .navbar-brand,
  .navbar-tabs {
    .navbar-item {
      align-items: center;
      display: flex; } }
  .navbar-link {
    &::after {
      display: inline-block; } }
  .navbar-menu {
    background-color: $navbar-background-color;
    box-shadow: 0 8px 16px rgba($black, 0.1);
    padding: 0.5rem 0;
    @media (max-width: 1088px) {
      box-shadow: none; }
    &.is-active {
      display: block; } }
  // Fixed navbar
  .navbar {
    &.is-fixed-bottom-touch,
    &.is-fixed-top-touch {
      @include navbar-fixed; }
    &.is-fixed-bottom-touch {
      bottom: 0;
      &.has-shadow {
        box-shadow: 0 -2px 3px rgba($black, 0.1); } }
    &.is-fixed-top-touch {
      top: 0; }
    &.is-fixed-top,
    &.is-fixed-top-touch {
      .navbar-menu {
        @include overflow-touch;
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto; } } }
  html,
  body {
    &.has-navbar-fixed-top-touch {
      padding-top: $navbar-height; }
    &.has-navbar-fixed-bottom-touch {
      padding-bottom: $navbar-height; } } }

@media (min-width: 992px) {
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: stretch;
    display: flex;
    @media (max-width: 1199px) {
      flex-direction: column; } }
  .navbar {
    min-height: $navbar-height;
    &.is-spaced {
      padding: $navbar-padding-vertical $navbar-padding-horizontal;
      .navbar-start,
      .navbar-end {
        align-items: center; }
      a.navbar-item,
      .navbar-link {
        border-radius: $radius; } }
    &.is-transparent {
      a.navbar-item,
      .navbar-link {
        &:hover,
        &.is-active {
          background-color: transparent !important; } }
      .navbar-item.has-dropdown {
        &.is-active,
        &.is-hoverable:hover {
          .navbar-link {
            background-color: transparent !important; } } }
      .navbar-dropdown {
        a.navbar-item {
          &:hover {
            background-color: $navbar-dropdown-item-hover-background-color;
 }            // color: $navbar-dropdown-item-hover-color
          &.is-active {
            background-color: $navbar-dropdown-item-active-background-color;
 } } } } }            // color: $navbar-dropdown-item-active-color
  .navbar-burger {
 }    // display: none
  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex; }
  .navbar-item {
    display: flex;
    &.has-dropdown {
      align-items: stretch; }
    &.has-dropdown-up {
      .navbar-link::after {
        transform: rotate(135deg) translate(0.25em, -0.25em); }
      .navbar-dropdown {
        border-bottom: $navbar-dropdown-border-top;
        border-radius: $navbar-dropdown-radius $navbar-dropdown-radius 0 0;
        border-top: none;
        bottom: 100%;
        box-shadow: 0 -8px 8px rgba($black, 0.1);
        top: auto; } }
    &.is-active,
    &.is-hoverable:hover {
      .navbar-dropdown {
        display: block;
        .navbar.is-spaced &,
        &.is-boxed {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0); } } } }
  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0; }
  .navbar-start {
    justify-content: flex-start;
    margin-right: auto; }
  .navbar-end {
    justify-content: flex-end;
    margin-left: auto; }
  .navbar-dropdown {
    background-color: $cp1;
    // border-bottom-left-radius: $navbar-dropdown-radius
    // border-bottom-right-radius: $navbar-dropdown-radius
    // border-top: $navbar-dropdown-border-top
    border-radius: 10px;
    box-shadow: 0 8px 8px rgba($black, 0.1);
    display: none;
    font-size: 0.875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: $navbar-dropdown-z;
    .navbar-item {
      padding: 0.375rem 1rem;
      white-space: nowrap;
      color: #fff; }
    a.navbar-item {
      padding-right: 3rem;
      &:hover {
        background-color: $navbar-dropdown-item-hover-background-color;
 }        // color: $navbar-dropdown-item-hover-color
      &.is-active {
        background-color: $navbar-dropdown-item-active-background-color;
 } }        // color: $navbar-dropdown-item-active-color
    .navbar.is-spaced &,
    &.is-boxed {
      border-radius: $navbar-dropdown-boxed-radius;
      border-top: none;
      box-shadow: $navbar-dropdown-boxed-shadow;
      display: block;
      opacity: 0;
      pointer-events: none;
      top: calc(100% + (#{$navbar-dropdown-offset}));
      transform: translateY(-5px);
      transition-duration: $speed;
      transition-property: opacity, transform; }
    &.is-right {
      left: auto;
      right: 0; } }
  .navbar-divider {
    display: block; }
  .navbar > .container,
  .container > .navbar {
    .navbar-brand {
      margin-left: -1rem; }
    .navbar-menu {
      margin-right: -1rem; } }
  // Fixed navbar
  .navbar {
    &.is-fixed-bottom-desktop,
    &.is-fixed-top-desktop {
      @include navbar-fixed; }
    &.is-fixed-bottom-desktop {
      bottom: 0;
      &.has-shadow {
        box-shadow: 0 -2px 3px rgba($black, 0.1); } }
    &.is-fixed-top-desktop {
      top: 0; } }
  html,
  body {
    &.has-navbar-fixed-top-desktop {
      padding-top: $navbar-height; }
    &.has-navbar-fixed-bottom-desktop {
      padding-bottom: $navbar-height; }
    &.has-spaced-navbar-fixed-top {
      padding-top: $navbar-height + ($navbar-padding-vertical * 2); }
    &.has-spaced-navbar-fixed-bottom {
      padding-bottom: $navbar-height + ($navbar-padding-vertical * 2); } }
  // Hover/Active states
  a.navbar-item,
  .navbar-link {
    &.is-active {
 }      // color: $navbar-item-active-color
    &.is-active:not(:hover) {
      background-color: $navbar-item-active-background-color; } }
  .navbar-item.has-dropdown {
    &:hover,
    &.is-active {
      .navbar-link {
 } } } }        // background-color: $navbar-item-hover-background-color


.display-flex {
  display: flex !important; }

.flex-direction-column {
  flex-direction: column; }


.navbar-item {
  .navbar-link {
    transition: ease all .2s;
    &:hover {
      color: $cp1; } }
  .navbar-dropdown {
    .navbar-item {
      transition: ease all .2s;

      @media (min-width: 992px) {
        &:hover {
          opacity: .8;
          color: #fff; } } } } }
