.loader {
    &__container {
        background-color: rgba(0, 0, 0, 0.8);
        height: 100%;
        width: 100%;
        position: fixed;
        transition: all 2s ease;
        z-index: 10000; }
    &__item {
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 8%;
            animation: girar 1.5s linear infinite alternate-reverse; } } }
@keyframes girar {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@keyframes girar {
    100% {
        opacity: 1; }
    0% {
        opacity: 0; } }

// &__container
// &__item
