.wha-button {
  font-size: 12px;
  background: $cp1;
  font-weight: bold;
  height: 40px;
  padding-left: 16px;
  padding-right: 32px;
  position: fixed;
  bottom: 10px;
  right: 16px;
  width: auto;
  border-radius: 8px;
  z-index: 500;
  min-width: 45px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background 0.2s ease-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;

  @media screen and (min-width: 425px) {
    font-size: 14px;
    height: 44px; }

  p {
    color: $cb; }

  img {
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
    display: block;
    transform: translateY(-8px);

    @media screen and (min-width: 425px) {
      width: 44px;
      height: 44px;
      transform: translateY(-10px); } } }
