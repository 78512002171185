$noticias-card-bg: #fff;
$noticias-font-color: #333333;
$noticias-font-color-secondary: #fff;
$noticias-primary: #00B3A2;
$noticias-hover: #00d4c0;
$noticias-superposition: rgba(0,0,0,0.5);
$noticias-box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);

// ............................................ { noticias } #

// Events list styles
.noticias {
	padding-bottom: 250px;

	&__padding {
		padding: 0 30px;
		max-width: 1200px;
		width: 100%;
		margin: 0 auto; }

	&__title {
		padding: 100px 20px 200px 20px;
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: $nosotros-font-color-neutral;

		@include desktop {
			padding: 80px 20px; } }

	&__principal {
		position: relative;
		top: -100px;
		z-index: 100;
		margin: 0 auto;
		padding-bottom: 30px;
		background: $noticias-card-bg;
		-webkit-box-shadow: $noticias-box-shadow;
		-moz-box-shadow: $noticias-box-shadow;
		box-shadow: $noticias-box-shadow;
		border-radius: 10px;
		margin-bottom: -100px;
		height: 500px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include tablet {
			height: 550px;
			top: -20px;
			grid-gap: 10px;
			align-items: center;
			margin-bottom: 30px;
			padding-left: 20px;
			align-items: flex-start; }

		&__superposition {
			background: $noticias-superposition;
			border-radius: 10px;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			z-index: 0; }
		&__img {
			border-radius: 10px;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			object-fit: cover;
			z-index: -1;
			display: none;

			@include tablet {
				display: block; }

			&--mobile {
				display: block;

				@include tablet {
					display: none; } } }

		&__content {
			padding: 0 15px;
			position: relative;
			width: 100%;

			@include tablet {
				width: 630px; }
			&__title {
				padding: 20px 0;
				font-size: 1.4rem;
				font-weight: bold;
				line-height: 2rem;
				color: $noticias-primary; }
			&__info {
                &__paragraph {
                    color: $noticias-font-color-secondary;
                    font-size: .9rem; } }
			&__button {
				button {
					background: $noticias-card-bg !important;
					color: $noticias-primary !important;
					max-width: 315px;
					width: 100%;
					margin: 30px 0 0 0;
					&:hover {
						color: $noticias-hover !important; }

					@include tablet {
						max-width: 190px; } } } }
		&__wave {
			background: url(../images/noticias/wave-noticias.svg) no-repeat 100% 0;
			background-size: contain;
			height: auto;
			position: absolute;
			top: 0px;
			right: 0px;
			border-top-right-radius: 10px;
			text-align: center;
			padding: 15px 30px 35px 90px;
			color: $noticias-primary;

			@include tablet {
				padding: 20px 50px 60px 120px; }

			&__day {
				font-size: 1.6rem;
				@include tablet {
					font-size: 2rem; } }
			&__month {
				font-size: .8rem;
				@include tablet {
					font-size: 1rem; } } } }

	&__cards {
		padding: 30px 0;

		&__title {
			font-size: 1.6rem;
			color: $noticias-primary;
			padding-bottom: 20px;
			text-transform: uppercase; }

		&__container {
			display: grid;
			grid-gap: 20px;
			justify-items: center;
			justify-content: center;
			padding: 20px 0;
			max-width: 1200px;
			margin: 0 auto;

			@include tablet {
				grid-template-columns: repeat(3, 1fr); }
			@include desktop {
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 40px; }
			@include fullhd {
				grid-gap: 40px; } } }

	&__show {
		button {
			background: $noticias-primary !important;
			max-width: 315px;
			width: 100%;
			margin: 0 auto;
			&:hover {
				background: $noticias-hover !important; } }

		&--less {
			display: none;
			button {
				&:hover {
					background: $noticias-hover !important; } } } } }

