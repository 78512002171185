$cartelera-card-box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);
$cartelera-card-primary: #E62E61;
$cartelera-card-hover: #ff2965;
$cartelera-card-alternative: #FAFAFA;
$cartelera-card-bg: #fff;
$cartelera-font-color-secondary: #B3B3B3;
$cartelera-font-color-primary: #333333;


$slider-main-pink: #E62E61;


.slider-bg-home {}

.cartelera-card {
    width: 100%;
    position: relative;
    transition: ease all .3s;
    margin: 0 auto 30px auto;
    display: none;
    max-width: 240px;

    @include desktop {
        max-width: 350px; }

    &:hover {
        transform: scale(1.02); }

    &:nth-child(-n+4) {
        display: block; }
    @include fullhd {
        &:nth-child(-n+12) {
            display: block; } }

    &__img {
        border-radius: 10px;
        width: 100%;
        max-width: 315px; }

    &__title {
        padding: 10px 0;
        font-size: .9rem;
        font-weight: bold;
        color: $cartelera-card-primary;

        &:hover {
            color: $cartelera-card-hover; } } }

.slider-cards--pink {
    .slick-arrow {
        &::before {
            color: $slider-main-pink; }
        &:hover {
            background: $slider-main-pink !important;
            &::before {}
            color: $slider-main-bg !important; } }
    .slick-dots {
        bottom: -25px;
        li {
            button {
                &::before {
                    color: $cartelera-card-alternative; } } }
        li.slick-active {
            button {
                &::before {
                    color: $slider-main-pink; } } } } }

.slider-header-home {
    padding: 0 30px;

    @include desktop {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px; }

    &--pink {
        color: $slider-main-pink; }

    &__title {
        font-size: 1.6rem; }

    &__button {

        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        justify-items: right;
        margin: 30px 0;
        max-width: 315px;

        &--pink {
            background: $cartelera-card-alternative !important;
            color: $slider-main-pink !important;
            &:hover {
                color: $cartelera-card-hover !important; } }

        @include desktop {
            margin: 0; }



        b {
            font-weight: bold;
            transform: rotate(180deg); }
        h4 {
            font-weight: bold; } } }
