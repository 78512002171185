$servicios-card-bg: #fff;
$servicios-font-color: #333333;
$servicios-font-color-secondary: #fff;
$servicios-primary: #FF9733;
$servicios-hover: #ffa652;
$servicios-superposition: rgba(0,0,0,0.5);
$servicios-box-shadow: 1px 1px 10px 0px rgba(112,112,112,0.2);

$slider-main-yellow: #FF9733;

.servicios-grid {
    top: -100px;
    position: relative;
    z-index: 100;

    @include desktop {
        top: -20px;
        display: grid;
        grid-template-columns: repeat(2, 0.5fr) 1fr;
        grid-template-rows: repeat(3, 1fr);
        gap: 20px; }

    &__box {
        height: 318px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: flex-end;
        margin-bottom: 20px;
        position: relative;
        @include desktop {
            height: 100%;
            padding-top: 100px; }

        &--small {
            height: 150px;
            @include desktop;
            height: 100%; }

        &--parking {
            grid-area: 1 / 1 / 3 / 3; }
        &--park {
            grid-area: 1 / 3 / 2 / 4; }
        &--pet {
            grid-area: 2 / 3 / 4 / 4; }
        &--area {
            grid-area: 3 / 1 / 4 / 2; }
        &--pool {
            grid-area: 3 / 2 / 4 / 3; }

        &__superposition {
            width: 100%;
            background: $servicios-superposition;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 0;
            border-radius: 10px; }
        &__img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: 10px; }
        &__title {
            color: $servicios-font-color-secondary;
            z-index: 100;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 1.2rem; }
        &__paragraph {
            color: $servicios-font-color-secondary;
            z-index: 100;
            font-size: .8rem;
            padding: 30px 0 10px 0;
            line-height: 1.4rem;
            @include desktop;
            width: 50%; }
        &__button {
            button {
                margin-top: 20px;
                background: $servicios-primary !important;
                max-width: 200px;
                &:hover {
                    background: $servicios-hover !important; } } } } }

.slider-cards--yellow {
    .slick-arrow {
        &::before {
            color: $slider-main-yellow; }
        &:hover {
            background: $slider-main-yellow !important;
            &::before {}
            color: $slider-main-bg !important; } }
    .slick-dots {
        bottom: -25px;
        li {
            button {
                &::before {
                    color: $servicios-card-alternative; } } }
        li.slick-active {
            button {
                &::before {
                    color: $slider-main-yellow; } } } } }

.slider-header-home {
    padding: 0 30px;

    @include desktop {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px; }

    &--yellow {
        color: $slider-main-yellow;
        margin-bottom: 130px;
        padding-right: 0;
        padding-left: 0;

        @include desktop {
            margin-bottom: 30px; } }

    &__title {
        font-size: 1.6rem; }

    &__button {

        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        justify-items: right;
        margin: 30px 0;
        max-width: 315px;

        &--yellow {
            background: $servicios-card-alternative !important;
            color: $slider-main-yellow !important;
            &:hover {
                color: $servicios-hover !important; } }

        @include desktop {
            margin: 0; }



        b {
            font-weight: bold;
            transform: rotate(180deg); }
        h4 {
            font-weight: bold; } } }
