.maintenance {
  height: 80vh;
  margin-top: -200px;
  margin-bottom: 280px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding: 20px;
  flex-direction: column;


  &__top {
    margin-bottom: 30px;

    &__circle {
      height: 290px;
      width: 290px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &__icon {
        max-width: 130px; } } }

  &__bottom {
    max-width: 290px; } }

