
$noticias-card-box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);
$noticias-card-primary: #00B3A2;
$noticias-card-hover: #00d4c0;
$noticias-card-alternative: #FAFAFA;
$noticias-card-bg: #fff;
$noticias-font-color-secondary: #B3B3B3;
$noticias-font-color-primary: #333333;


$slider-main-blue: #00B3A2;

.noticias-card {
    width: 100%;
    position: relative;
    -webkit-box-shadow: $noticias-card-box-shadow;
    -moz-box-shadow: $noticias-card-box-shadow;
    box-shadow: $noticias-card-box-shadow;
    -webkit-appearance: none;
    transition: ease all .3s;
    margin: 0 auto 30px auto;
    display: none;
    border-radius: 10px;
    max-width: 240px;
    height: 470px;


    @include desktop {
        max-width: 315px;
        height: 490px; }

    &:hover {
        transform: scale(1.02); }

    &:nth-child(-n+4) {
        display: block; }
    @include fullhd {
        &:nth-child(-n+12) {
            display: block; } }

    &__img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        max-width: 315px; }

    &__date {
        display: flex;
        padding: 20px;
        font-size: .9rem;

        &__day {
            font-weight: bold;
            color: $noticias-font-color-primary;
            padding-right: 5px; }
        &__month {
            color: $noticias-font-color-secondary; } }

    &__title {
        padding: 0 20px;
        font-size: .9rem;
        font-weight: bold;
        color: $noticias-card-primary;
        padding-bottom: 20px;
        transition: ease all .3s;
        &:hover {
            color: $noticias-card-hover; } }

    &__paragraph {
        padding: 0 20px;
        color: $noticias-font-color-primary;
        font-size: .8rem;
        line-height: 1.5rem;
        padding-bottom: 40px; } }

.slider-cards--blue {
    .slick-arrow {
        &::before {
            color: $slider-main-blue; }
        &:hover {
            background: $slider-main-blue !important;
            &::before {}
            color: $slider-main-bg !important; } }
    .slick-dots {
        bottom: -25px;
        li {
            button {
                &::before {
                    color: $noticias-card-alternative; } } }
        li.slick-active {
            button {
                &::before {
                    color: $slider-main-blue; } } } } }

.slider-header-home {
    padding: 0 30px;

    @include desktop {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px; }

    &--blue {
        color: $slider-main-blue; }

    &__title {
        font-size: 1.6rem; }

    &__button {

        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        justify-items: right;
        margin: 30px 0;
        max-width: 315px;

        &--blue {
            background: $noticias-card-alternative !important;
            color: $slider-main-blue !important;
            &:hover {
                color: $noticias-card-hover !important; } }

        @include desktop {
            margin: 0; }



        b {
            font-weight: bold;
            transform: rotate(180deg); }
        h4 {
            font-weight: bold; } } }
