$cartelera-detail-bg: #fff;
$cartelera-detail-primary: #E62E61;
$cartelera-detail-hover: #ff2965;
$cartelera-detail-circle: #EAEBEC;
$cartelera-detail-secondary-font: #333333;

.cartelera-detail {
    background: $cartelera-detail-bg;
    padding: 0 30px;
    padding-bottom: 50px;

    @include tablet {
        padding-bottom: 150px; }
    @include desktop {
        padding-bottom: 250px; }

    &__header {
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 50px;

        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 0 50px 0; }

        &__button {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            max-height: 44px;
            background: $cartelera-detail-primary !important;
            &:hover {
                background: $cartelera-detail-hover !important; }

            @include tablet {
                margin: 0; }
            b {
                font-size: 1rem;
                font-weight: 700; }
            h4 {
                font-size: .9rem;
                font-weight: 700; } }
        &__social {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 30px;
            align-items: center;

            @include tablet {
                padding: 0; }

            @include desktop {
                flex-direction: row;
                align-items: center; }
            p {
                font-size: .8rem;
                padding-bottom: 10px;

                @include desktop {
                    padding-right: 15px;
                    padding-bottom: 3px; } }
            a {
                color: $cartelera-detail-primary;
                font-size: 1.2rem;
                transition: ease all .3s;

                &:hover {
                    transform: scale(1.1); }

                &:not(:last-child) {
                    padding-right: 15px; } } } }

    &__container {
        max-width: 1200px;
        margin: 0 auto;
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
            padding-bottom: 50px; }
        &__img {
            width: 100%;
            margin: 0 auto;
            max-width: 450px;
            border-radius: 10px;
            display: block;

            @include desktop {
                max-width: 670px; } }

        &__info {

            &__title {
                font-weight: bold;
                padding-top: 20px;
                font-size: 1.6rem;
                line-height: 1.4rem;
                color: $cartelera-detail-primary; }

            &__type {
                display: flex;
                margin-top: 30px;

                &__box {
                    border: 1px solid $cartelera-detail-primary;
                    border-radius: 5px;
                    padding: 2px 8px;
                    margin-right: 10px;
                    font-size: 0.8rem;

                    p {
                        color: $cartelera-detail-primary; } } }


            &__details {

              padding-top: 30px;
              @include desktop {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                padding-top: 50px; } }

            &__box {
                display: flex;
                font-size: .9rem;
                padding-bottom: 10px;
                &__icon {
                    padding-right: 15px; }
                &__title {
                    font-weight: bold;
                    line-height: .9rem; } }
            &__schedule {

                @include desktop {
                    padding-top: 30px; }
                &__title {
                    padding: 20px 0;
                    font-weight: bold;

                    @include desktop {
                        padding-bottom: 30px; } }

                &__boxes {
                    display: flex;
                    flex-wrap: wrap; }

                &__box {
                    border: 1px solid $cartelera-detail-secondary-font;
                    border-radius: 5px;
                    padding: 2px 8px;
                    margin-right: 10px;
                    margin-bottom: 10px;

                    p {
                        color: $cartelera-detail-secondary-font;
                        font-size: .8rem; } } }
            &__description {
                color: $cartelera-detail-secondary-font;
                padding-bottom: 20px;

                @include desktop {
                    padding-bottom: 50px; }

                &__title {
                    font-weight: bold;
                    padding: 20px 0;
                    font-size: 1.2rem; }
                &__paragraph {
                    font-size: 0.8rem;
                    color: $cartelera-detail-secondary-font; } }
            &__trailer {
                color: $cartelera-detail-secondary-font;
                &__title {
                    font-weight: bold;
                    padding-top: 30px;
                    font-size: 1.2rem; }
                iframe {
                    border-radius: 5px; } } } } }

.movie-active {
    background: $cartelera-detail-primary;
    p {
        color: #fff; } }
