$card-color: $text !default;
$card-background-color: $white !default;
$card-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;

$card-header-background-color: none !default;
$card-header-color: $text-strong !default;
$card-header-shadow: 0 1px 2px rgba($black, 0.1) !default;
$card-header-weight: $weight-bold !default;

$card-content-background-color: none !default;

$card-footer-background-color: none !default;
$card-footer-border-top: 1px solid $border !default;

$radius: 10px;

.card {
  background-color: $card-background-color;
  box-shadow: $card-shadow;
  color: $card-color;
  max-width: 100%;
  max-height: 130px;
  height: 100%;
  position: relative;
  border-radius: $radius;

  &--ivoice {
    // +tablet
    display: flex;
    // grid-template-columns: 1fr 1fr 1fr 1fr !important
    border-radius: $radius; }

  &__image {
    &--ivoice {
      grid-column-start: 1;
      grid-column-end: 3;
      align-self: center;
      justify-self: center;
      height: 100%;
      // padding: 1em

      @include tablet {
        grid-column-start: 1;
        grid-column-end: 2; }


      // background-color: #c7c7c7
      img {
        width: 100%;
        max-width: 133px;
        object-fit: cover;
        max-height: 133px;
        height: 100%;
        border-radius: 10px;
        // +tablet

        //   border-top-right-radius: 0
 } } }        //   border-bottom-left-radius: $radius

  &__content {
    &--ivoice {
      grid-column-start: 3;
      grid-column-end: 5;
      padding-top: 0;
      padding: 1em;

      @include tablet {
        grid-column-start: 2;
        grid-column-end: 5;
        padding: 1em;
        padding-top: 10px; } } }

  &__title {
    &--ivoice {
      color: #903392;
      font-weight: bold;
 } }      // margin-bottom: 3px
  &__subtitle {
    &--ivoice {
 } }      // margin-bottom: 3px

  &__date {
    font-size: 12px;
    &--ivoice {
      color: #d6d6d6;
 } }      // margin-bottom: 3px
  &__status {
    line-height: 22px;
    &--success {
      color: #52C40C; }
    &--waiting {
      color: #F06031; }
    &--ivoice {} }
  &__name {
    font-size: 12px; } }



.card-header {
  background-color: $card-header-background-color;
  align-items: stretch;
  box-shadow: 0px 3px 10px 0px #f3f3f3;
  display: flex; }

.card-header-title {
  align-items: center;
  color: $card-header-color;
  display: flex;
  flex-grow: 1;
  font-weight: $card-header-weight;
  padding: 0.75rem;
  &.is-centered {
    justify-content: center; } }

.card-header-icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.75rem; }

.card-image {
  display: block;
  position: relative; }

.card-content {
  background-color: $card-content-background-color;
  padding: 1.5rem; }

.card-footer {
  background-color: $card-footer-background-color;
  border-top: $card-footer-border-top;
  align-items: stretch;
  display: flex; }

.card-footer-item {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  padding: 0.75rem;
  &:not(:last-child) {
    border-right: $card-footer-border-top; } }

// Combinations

.card {
  .media:not(:last-child) {
    margin-bottom: 0.75rem; } }





// ------------------------------------------ CARD ALMACENES
$card-radius: 10px;
.card-almacen {
  background-color: white;
  padding: 0 !important;
  border-radius: $card-radius;
  box-shadow: 0 2px 3px rgba($black, 0.1);

  // margin-bottom: 1em

  @include tablet {
    display: grid;
    // flex-direction: column
    // justify-content: space-between
    height: 399px; }
  @include desktop {
    height: 390px; }
  &__thumbnail {
    &-link {
      display: block; } }
  &__img {
    width: 100%;
    border-top-left-radius: $card-radius;
    border-top-right-radius: $card-radius;
    @include tablet {
      height: 190px; } }
  &__title {
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1em; }
    &-link {
      font-weight: bold;
      font-size: 15px;
      &:hover {
        color: #903392; } } }
  &__icon {
    // font-size: 1.3em
    cursor: pointer;
    color: #f0f0f0;
    &--heart {
      font-size: 1.4em;
      &:hover {
        color: #FF0000; }
      &.active {
        color: #FF0000; } }

    &--marker {
      font-size: 18px;
      margin-right: 5px; } }

  &__description {
    padding: .2em 1em; }
  &__text {
    font-size: 15px;
 }    // max-width: 80%
  &__footer {
    align-self: end;
    &-link {
      display: block;
      padding-top: 8px;
      padding-bottom: 10px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: 2px solid #ececec;
      cursor: pointer;
      color: #903392;
      font-weight: bold;
      font-size: 14px;
      &:hover {
        border-top: 2px solid #903392;
        background-color: #903392;
        border-bottom-left-radius: $card-radius;
        border-bottom-right-radius: $card-radius;
        color: white !important; } } }
  &__local {
 } }    // text-transform: capitalize




// ------------------------------------------ CARD MARKETPALCE CON BASE CARD ALMACEN

.card {
  &--marketplace {
    min-height: 410px;
    max-height: max-content;
    & .card-almacen__img {
      padding: 2em;
      object-fit: contain; }
    & .card-almacen__thumbnail {}
    & .card-almacen__title-container {
        padding: 0 1rem;
        justify-content: center; }
    & .card-almacen__title,
    & .card-almacen__text {
        text-align: center;
        min-height: 42px; }
    & .card-almacen__footer {
      & .card-almacen__footer-link {
        border-top: 0;
        text-align: center;
        padding-bottom: 15px; } }
    & .card-almacen__button {
        margin: 0 auto;
        margin-bottom: .2rem;
        padding: 0 1rem; }
    & .card-almacen__local {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem; }
    & .card-almacen__footer-link:hover img {
        filter: brightness(8); } } }
