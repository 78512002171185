// .......................................................................... { footer main } #
.footer {
  position: relative;
  //To hide whatsapp button
  z-index: 600;
  background: $cp1;
  color: $cb;
  padding: 3rem 1rem;
  z-index: 600;
  @include sm {
    padding: 3rem 1.5rem; }

  &__col {
    .followers {
      margin-bottom: 40px;
      padding-left: .75em; } }


  &__logo {
    width: 100%;
    margin-bottom: 20px;
    @include xs {
      text-align: center; }
    @include lg {
      text-align: left; }
    img {
      width: 200px;
      height: 76px; } }

  &__title {
      color: $cp7;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 25px;
 }      // text-align: center

  &__par {
      font-size: 0.8rem;
      font-weight: bold;
      line-height: 1.3rem;
      color: #fff;
      @include xs {
        text-align: center; }
      @include lg {
        text-align: left; } }

  &__link {
      color: $cb;

      &:hover {
        color: $cg3; } }

  &__list {
      li {
        font-weight: 500;
        font-size: 0.85rem;
        margin: 6px 0;
        letter-spacing: 1px;

        a {
          color: $cb;
          &:hover {
            color: $cp2;
            transition: ease all .8s; } } } }
  // .......................................................................... { form footer } #
  &__form {
    .field {
      margin-top: 30px;
      .control {
        position: relative;
        width: 100%;
        max-width: 400px;
        margin: 0 auto; } } }
  &__input {
    background: $cb;
    height: 40px;
    width: 100%;
    font-weight: 600;
    border-style: none;
    border-radius: 19px;
    padding: 5px 45px 5px 20px;
    font-size: 0.9em;
    color: $cg2;

    @include placeholder_color($cg2);

    &:focus {
      outline: none; } }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    display: block;
    border-style: none;
    background: none;
    transform: rotate(-90deg);
    padding: 12px 10px;
    cursor: pointer;
    border-radius: 0 0 19px 19px;
    &:hover {
      background: $cp7;
      color: $cb; } }

  .imperialito {
    font-size: 0.85rem;
    margin-top: 1.3rem;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    @include md {
      margin-left: -.75rem;
      margin-right: -.75rem; }
    .info {
      padding-left: 0;
      padding-top: 1.3rem; } } }

// .......................................................................... { footer auxiliar } #
.footer-aux {
  position: relative;
  //To hide whatsapp button
  z-index: 600;
  background: $cp1;
  font-weight: 300;
  font-size: 0.8rem;
  color: $cp4;
  padding: 0 1rem;
  text-align: center;
  position: relative;
  //max-height: 63px
  @include md {
    text-align: left; }

  .box-legal, .box-autor {
    color: $cp7; }

  .box-legal {
    .legal_1 {
      color: $cp7 !important;
      font-size: 13px; } }

  .link--autor {
    img {
      margin-top: 15px;
      width: 100px; } }

  &.m--mapa {
    position: fixed;
    bottom: 0;
    padding: 0.5rem;
    width: 100%;
    left: 0;
    z-index: 200;

    .box-legal {
      font-size: 0.7em; }
    .box-autor {
      margin: 0;
      padding: 0;
      font-size: 0.8em;
 } } }      // display: none



.box {
  &-noticias {
    .footer__title {
      text-align: center;
      margin-top: 40px; } } }

.followers {
  &__columns {
    text-align: center;
    justify-content: center;
    margin-top: 20px;
 } }    // @include sm

.autor {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  align-items: center;

  @include desktop {
    padding: 0; }

  &__text {
    padding-right: 5px;
    color: $cp7 !important;
    font-size: 13px; } }
