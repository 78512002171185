.customSelect,.custom__select__form {
  position: relative;
  padding: 0;
  &:focus, &:hover {
    box-shadow: none; }
  select {
    display: none; }






  /* Style items (options): */
  &__items-wrapper {
    padding: 1rem;
    position: absolute;
    top: 100%;
    max-height: 200px;
    overflow: scroll;
    left: 0;
    right: 0;
    z-index: 99;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background: white;
    border-radius: 1.25rem;
    box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.1);
    &--hidden {
      display: none; } }
  /* style the items (options), including the selected item: */
  &__item,&__select {
    cursor: pointer;
    margin: 0.1rem 0; }

  &__select {
    // border: 1px solid #D2D3D6
    background-color: white;
    border-radius: 50px;
    padding: .45rem 2rem .45rem 1rem;
    color: #B3B3B3;
    box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);
    font-weight: normal;

    /* Style the arrow inside the select element: */
    &.select-arrow-active:after {
      transform: rotate(-180deg); }
    &:after {
      font-family: 'icomoon';
      content: "\e928";
      position: absolute;
      top: calc(50% - 10px);
      right: 1rem;
 } }      // transition: transform .3s ease-in-out


  &__item {
    padding: 0.1em 0.5em;
    position: static;
    white-space: nowrap;
    display: block;
    &:focus, &:hover {
      background-color: rgba($cp1, 0.1);
      color: $cp1;
      border-radius: 1em; } }
  /* Hide the items when the select box is closed: */ }
