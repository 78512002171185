// .......................................................................... { head main } #
.bg-white {
  background: $cb; }

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1200;
  background: $cb;






 }  // NEW STYLES TEST
.header-home {
  background: $cb;
  color: $cn;
  padding: 10px 40px;
  height: 105px;
  @media (max-width: 1199px) {
    height: 80px; }
  @media (max-width: 614px) {
    padding: 10px 30px;
 } }    // padding-right: 40px
.header__nav {
  padding: 15px 0px;
  @media (max-width: 1199px) {
    padding: 5px 0px; } }


.logo {
  &__img {
    width: 140px; }
  &__container {
    max-width: 200px;
    padding: 10px 0;
    padding-left: 5px;
    display: flex;
    align-items: center; } }

// HORARIOS
.horario {
  position: relative;

  &--mobile {
    // padding: 0 15px
    overflow: auto; }

  &__contenedor {
    display: flex;
    @media (max-width: 1199px) {
      display: block; } }
  &__container {
    display: flex;
    justify-content: space-between;
    background-color: #F0F0F0;
    border-radius: 50px;
    // padding: 0px 10px
    font-size: 11px;
    white-space: nowrap;
    margin-right: 15px;
    // -––––@media (min-width: 768px)
    //   display: none

    &--mobile {
      margin-top: 20px;
      margin-right: 0;
      padding: 5px 0;
      align-items: center;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      max-width: 350px; } }
  &__text {
    padding-left: 10px; }


  &__table {


    &__container {
      display: block;
      position: relative;
      top: 10px;
      width: 100%;
      background-color: #f0f0f0;
      border-radius: 20px;
      z-index: 2000;
      margin-bottom: 20px;

      @media (min-width: 992px) {
        display: block;
        position: fixed;
        width: 370px;
        top: 4.7em;
        margin: 0 auto;
        border-left: 1px solid $cg4;
        border-right: 1px solid $cg4;
        border-bottom: 1px solid $cg4; }
      @media (min-width: 1440px) {
        top: 3.7em; }
      &--mobile {
        margin-top: 20px; }

      &.active {
        display: block; } }

    &__title {
      background-color: $cg4;
      text-align: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: .8em 0;
      font-size: 16px;
      h3 {
        color: $cp1;
        font-weight: bold;
 } }        // font-size:
    &__content {
      padding: .8em 1em;
      font-size: 14px; }

    &__row {
      display: flex;
      justify-content: space-between;
      padding: .8em 0; } }
  &__text {
    // margin-right: 6%
    // padding: 5px
    // padding-left: 10px
    display: flex;
    align-items: center; }

  &__buttons {
    a {
      display: block;
      width: 110px;
      color: $cp1;
      font-weight: bold;
      // border-bottom-right-radius: 25px
      // border-top-right-radius: 25px
      padding: 5px;
      text-align: center;
      transition: ease all 1s;
      // &:hover
      //   background-color: $cp1
      //   color: $cb
      //   border-radius: 25px
      // @media (min-width: 1296px)
      //   &:hover
      //     border-bottom-left-radius: 0
      //     border-top-left-radius: 0
      b {
        margin-left: 5px; } } } }

// BOTON HORARIOS EN MODOD MOBILE
#horarios__menu {
  color: $cp1;
  font-weight: bold;
  b {
    ransform: rotate(0deg);
    transition: all .6s ease; }

  &.active {
    b {
      transform: rotate(180deg);
      transition: all .6s ease;
 } } }      // transform: rotate(20deg);

.info__data {
  &__content,
  &__box {
    display: flex;
    margin-right: 10px;
    @media (max-width: 1199px) {
      margin-right: 0;
      padding: .5em 0; } }

  &__content {
    justify-content: flex-end;
    @media (max-width: 1199px) {
      display: block; } }


  &__box {
    align-items: center; }
  &__text {
    font-size: 11px;
    // white-space: nowrap
    margin-left: 10px; } }



// ................................................ { Followers } #
.followers {
  margin-top: 30px;
  border-top: 1px solid #9133922d;
  padding: 30px 15px;
  &__content {
    display: flex;
    justify-content: center; }
  // position: absolute
  // top: 20%
  // width: 100%
  // @include rwd(1088)
  //   width: 260px
  //   margin: 0 0 0 0
  //   top: 9px
  //   right: 0

  &__title {
    display: block;
    color: $cp2;
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    // @include rwd(1088)
 }    //   display: none

  // @include rwd(1404)
  //   top: 5px

  &__content {
    // width: 215px
 }    // margin: 0 auto


  &__link {
    margin-right: 15px;
    //font-size: 1.8rem
    // font-size: 1rem
    // line-height: 1.6

    // @include md
 }    //   font-size: 1.3rem


  // .columns.header-home
  //   &.is-mobile
  //     & > .column
  //       width: 39px
  //       height: 32px
 }  //       margin: 0 2px




// ................................................ { Navbar } #
.navbar {
  &-start {
    width: 100%; }


  &-menu {
    background: none;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;
    justify-content: center;
    flex-direction: column;

    @include rwd(1200) {
      flex-direction: row; } }

  &-item {
    color: $cg1;
    display: block;
    text-align: left;
    // text-transform: capitalize
    font-size: 12px;
    font-weight: 700;
    display: block;
    // width: 100%
    letter-spacing: 0.04rem;
    @include rwd(1088) {
      text-align: center;
 }      // width: 11.285%

    &.active {
      position: relative;
      color: $cp1;
      &.home-icon {
        @supports(filter: invert(100%)) {
          .home-icon {
            filter: invert(100%);
            filter: invert(20%) sepia(100%) saturate(4000%); } } } }


    &:hover {
      background: none !important;
      color: $cp1; } } }


.box {
  &__login {
    display: flex;
    // justify-content: flex-end
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
    // position: relative
    // right: -130px

    &__content {
      width: 100%; }
    @media (max-width: 1199px) {
      justify-content: flex-start;
 } }      // padding-left: 1.4em
  &__content {
    @media (max-width: 1199px) {
      padding-left: .75em; } }
  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;
    @media (max-width: 1199px) {
      justify-content: flex-start; } } }


.navigation {
  @media (max-width: 1199px) {
    padding: 0; }
  &__top {
    justify-content: flex-end;
    @media (max-width: 992px) {
      display: block !important; }


    &__column {
      justify-content: flex-end;
      overflow: auto;
      @media (max-width: 1199px) {
        display: none;
        padding: 1.5em;
        position: absolute;
        background-color: $cb;
        height: 100vh;
        width: 100%;
        left: 0;
        top: 72px;
        z-index: 2500; } } }



  &__down {
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      display: flex;
      flex-direction: row;
      // display: block !important
      // flex-direction: column
      // height: 100vh
 }      // width: 100%
    &__column {
      overflow: auto;
      display: none;
      padding: 1.5em;
      position: absolute;
      background-color: $cb;
      height: 100vh;
      width: 100%;
      left: 0;
      top: 72px;
      z-index: 2600;


      @media (min-width: 992px) {
        height: 285px;
        top: 85px;
        width: 99%;
        margin: 0 auto;
        border-radius: 10px; } } } }


#menu__container {
  // display: block
  // // position: absolute
  // // top: 110%
  // // left: 0
  // @media (max-width: 1199px)
  //   display: none
  //   height: 100vh
  //   width: 100%
  //   // background-color: $cg4
  //   &.active
 }  //     display: block



.header-home {


  // head main
  #head-main {
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @include rwd(1088) {
      position: initial; }

    .columns {
      .column {
        padding: 5px 0 0; }
      &:last-child {
        margin-bottom: 0; } }

    .column.is-8 {
      width: 100%;
      @include rwd(769) {
        width: 100%; }
      @include rwd(1088) {
        width: 66.66667%; } }
    .box-logo {
      width: 55px;
      @include rwd(1088) {
        width: 16.66667%; } }


    .logo_1 {
      padding: 0 0 0 10px;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 166px;
      // width: 50px
      z-index: 2;
      img {
        width: 140px; }
      @include rwd(1200) {
        width: calc( 100% - 1080px ) !important; } }


    .logo-sm {
      width: 43px;
      left: 12px;
      top: 7px;
      position: relative;
      display: flex;
      align-items: center;
      width: 100px;
      padding-left: 10px;
      z-index: 1100; }


    // ................................................ { Menu - Navbar } #
    #navbar_main {
      background: none;
      min-height: 52px;
      height: auto; }

    .navbar-burger {
      height: 4.25rem;
      margin: 0 14px 0 auto;
      @include rwd(1088) {
        height: 3.25rem; }
      span {
        color: $cp1;
        height: 2px; } }

    .navbar-start {
      width: 100%; }

    .navbar-menu {
      background: none;
      padding-top: 5px;
      justify-content: center;

      &.nav-mobile {
        height: 100vh;
        padding: 0 20px 20px;
        //height: 90vh
        overflow-y: scroll;
        background: $cb;
        @include landscape() {
          height: 100vw; } }

      .navbar-item {
        color: $cg1;
        display: block;
        text-align: left;
        text-transform: capitalize;
        font-size: 0.8rem;
        font-weight: 600;
        display: block;
        width: 100%;
        letter-spacing: 0.04rem;
        @include rwd(1088) {
          text-align: center;
          width: 14.285%; }

        &:hover {
          background: none;
          color: $cp1; }


        // &.is-hoverable
        //   &:hover .navbar-dropdown
        //     display: none
        //     @include rwd(1088)
        //       display: block

        &.active {
          position: relative;
          color: $cp1;
          font-weight: bold; }

        .navbar-link {
          color: $cg1;
          width: 100%;
          display: block;
          text-align: left;
          @include rwd(1088) {
            text-align: center;
            &::before {
              background: transparent;
              content: '';
              position: absolute;
              top: calc(100% + 12px);
              left: 0;
              right: 0;
              margin: auto;
              padding: 5px;
              width: 90%; } }

          &:hover {
            background: none;
            color: $cp1; }

          &::after {
            content: '\e902';
            font-family: $icomoon-font-family;
            border: 0;
            margin-top: -9px;
            left: 95%;
            transform: none;
            display: block;
            @include sm {
              left: 68%; }
            @include lg {
              left: 90%; }
            @include xl {
              left: 80%; }
            @include rwd(1400) {
              left: 73%; } } }



        .navbar-dropdown {
          top: 120%;
          display: none;
          border-radius: 8px;
          border: none;
          box-shadow: none;
          margin: 0 0 0 16px;
          z-index: 200;
          @include md {
            margin: 0; }

          .navbar-item {
            background: $cb;
            width: 100%;
            display: block;
            text-align: left;
            padding: 8px 20px;
            color: #737380;

            &:hover {
              color: $cp1 !important; }

            @include rwd(1088) {
              text-align: left;
              color: $cg1 !important; }



            &:first-child {
              padding-top: 0;
              @include md {
                padding-top: 20px; } }
            &:last-child {
              padding-bottom: 0;
              @include md {
                padding-bottom: 20px; } }
            @include rwd(1088) {
              background: $cb;
              color: $cp8; }

            &:hover {
              background: none !important;
              color: $cp1;
              @include rwd(1088) {
                background: $cb !important; } } } }
        &:hover {} } } }


  // ................................................ { Header - Auxiliar } #
  #head-aux {
    padding: 5px 10px;
    //height: 81vh
    display: none;

    @include rwd(1088) {
      height: 55px;
      display: block !important; }

    @include md {
      padding: 5px 10px 8px; }
    // ................................................ { Followers } #
    // .followers
    //   position: absolute
    //   top: 20%
    //   width: 100%
    //   @include rwd(1088)
    //     width: 260px
    //     margin: 0 0 0 0
    //     top: 9px
    //     right: 0

    //   &__title
    //     display: block
    //     color: $cp2
    //     font-size: 0.9rem
    //     width: 100%
    //     text-align: center
    //     margin-bottom: 1rem
    //     margin-top: 1rem
    //     @include rwd(1088)
    //       display: none

    //   @include rwd(1404)
    //     top: 5px

    //   &__content
    //     width: 215px
    //     margin: 0 auto


    //   &__link
    //     //font-size: 1.8rem
    //     font-size: 1.2rem
    //     line-height: 1.6
    //     @include md
    //       font-size: 1.3rem

    //   .columns
    //     &.is-mobile
    //       & > .column
    //         width: 39px
    //         height: 32px
 }    //         margin: 0 2px

  .row-info {
    width: 100%;
    margin: 0 auto;
    padding-left: 0;
    //height: 90vh
    height: 100vh;
    transition: all 0.3s ease-out;
    opacity: 0;
    position: relative;
    // @include landscape()
    //   height: 110vw

    // display: none
    @include rwd(1088) {
      display: block;
      height: auto;
      padding-left: 0;
      opacity: 1;
      position: static; }
    @include lg {
      max-width: 90%;
      padding-right: 40px; }
    @include rwd(1087) {
 }      // display: none
    &.on {
      transition: all 0.3s ease-out;
      display: block; }

    &.opacity {
      opacity: 1; } }

  .row-control {
    display: block;
    @include rwd(1088) {
      display: none; } }


  // ................................................ { Horarios } #
  .mod-horarios {

    font-weight: 600;
    font-size: 0.8rem;
    position: absolute;
    width: 100%;
    margin: 0;
    top: 28%;
    @include rwd(1088) {
      left: 90px;
      top: 5px;
      position: relative; }


    &__title {
      color: $cp2;
      text-align: center;
      display: block;
      padding: 10px;
      font-size: 0.8rem;
      @include sm {
        padding: 20px 10px;
        font-size: 1rem; } }

    &-on {
      position: absolute;
      z-index: 120;
      padding: 0;
      top: 39px;
      background: $cg4;
      width: 100%;
      display: block;
      border: 1px solid transparent;
      border-radius: 8px;
      left: 0;
      margin-top: 20px !important;

      @include rwd(1088) {
        max-width: 410px;
        display: none;
        left: auto;
        margin-top: 0 !important; }

      @include md {
        top: 39px; } }

    &__head {}

    &__hora {
      display: block;
      padding: 11px 10px 3px;
      width: 100%;
      text-align: center;
      margin: 27px 0;

      @include md {
        padding: 0 10px;
        text-align: left;
        display: inline; } }

    &__btn {
      position: absolute;
      right: 0;
      top: 0;
      background: $cp1;
      color: $cb;
      cursor: pointer;
      padding: 0.7rem 2rem;
      text-align: center;
      width: 100%;

      @include md {
        width: 150px; } }

    .table {
      font-size: 0.9rem;
      border: 1px solid transparent;
      border-radius: 8px;
      td {
        padding: 5px 20px;
        border: 0 solid $cb;
        text-align: center;

        @include sm {
          text-align: left;
          padding: 20px; } }

      &__row:nth-child(odd) {
        background: $cb; }

      &__data {
        &-1 {
          width: 100%;
          font-weight: bold;
          display: block;
          @include sm {
            display: inline-block;
            width: 49%; } }
        &-2 {
          width: 100%;
          font-weight: 300;
          display: block;
          @include sm {
            display: inline-block;
            width: 49%; } } } }

    &__content {
      color: $cn;
      padding: 0 20px 20px;
      display: inline-block;
      vertical-align: top;
      position: relative;
      width: 100%;
      @include rwd(1088) {
        width: 360px;
        position: absolute;
        right: 710px;
        top: -1.5px;
        padding: 0 30px 20px; } }

    .text_horarios {
      display: none;
      background: $cg4;
      text-align: center;
      color: $cg1;
      padding: 7px 15px;
      border-radius: 50px;
      font-size: 13px;
      @include rwd(1200) {
        display: block;
        text-align: left; } }

    &__buttons {
      position: absolute;
      right: 0;
      top: 0;
      width: 130px;
      display: none;
      background: $cg3;
      @include rwd(1088) {
        display: block; } }

    &__button {
      border-style: none;
      border-radius: 0;
      box-shadow: none;
      width: 100%;
      font-size: 0.8rem;
      height: 33px;
      position: relative;
      color: $cp1;
      background: $cg4;
      border-radius: 50px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;

      &:hover {
        background: $cg4; }

      @include rwd(1200) {
        border-radius: 0;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px; }

      b {
        margin: 0 5px;
        padding-top: 2px; } } }


  .box-horario-mobile {
    position: absolute;
    top: 25px;
    display: block;
    left: 67px;
    //@include md
    //left: 2%
    @include rwd(1088) {
      display: none; }
    &__link {
      font-size: 0.8rem;
      color: $cp1;
      font-weight: 400;
      letter-spacing: 1px;
      margin-left: 70px;
      .icon {
        &::before {
          transform: rotate(0deg);
          transition: all 0.3s ease; } }
      &.active {
        color: $cp2;

        .icon {
          &::before {
            transform: rotate(180deg);
            transition: all 0.3s ease; } } } } }

  // ................................................ { Info - Data } #
  .info-data {
    font-size: 0.8rem;
    padding: 20px 0;
    color: $cg1;
    letter-spacing: 0.04rem;
    text-align: center;
    width: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    top: -275%;

    @include rwd(1088) {
      position: absolute;
      padding: 2px 0 0 30px;
      text-align: left;
      max-width: 510px;
      font-size: 0.7rem;
      display: inline-block;
      vertical-align: top;
      left: auto;
      right: 220px;
      top: -3px;

      &__content {
        display: flex;
        align-items: center; } }

    @include rwd(1404) {
 }      // padding: 9px


    &__box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // text-align: center

      .icon-s {
        background-color: $cg4;
        text-align: center;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 50%;
        color: $cp1;
        font-size: 1.2rem;
        margin-right: 10px; }

      // @include rwd(1404)
      //   width: 43%
      //   display: inline-block
 }      //   vertical-align: top
    // .ico
    //   position: absolute
    //   top: 24px
    //   left: 0
    //   display: none
    //   @include xl
    //     left: -19px
 }    //     display: block

  // { control show/hide mobile } ........ #
  .row-control {
    .cont-icon {
      width: 100%;
      text-align: center;
      .icon {
        color: $cb;
        display: block;
        width: 30px;
        height: 12px;
        margin: 0 auto;
        cursor: pointer; } } }

  .box-login {
    text-align: center;
    // padding: 0 0 7px
    display: none;
    @include rwd(1088) {
      display: block;
      position: relative; }

    a.dropdown-item {}
    .dropdown-content {
      background: none;
      box-shadow: none;
      color: $cb;
      border-radius: 8px;

      @include rwd(1088) {
        box-shadow: 0 0 1px $cp4;
        padding: 10px 10px 20px;
        background: $cb;
        color: $cn; } }

    &__content {
      width: 269px;
      position: relative;
      right: inherit;
      margin: 0 auto;
      display: block;
      text-align: left;

      @include rwd(1088) {
        width: 100%; } }

    &__avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: relative;
      top: 0;
      left: -12px; }

    &__menu {
      // margin: 0 20px 0 0
      .dropdown-menu {
        // position: inherit
        top: 50px;
        @include rwd(1088) {
          left: -104px;
          top: 55px;
          position: absolute; } } }


    .button {
      .icon:last-child:not(:first-child) {
        transition: all 0.5s ease;
        transform: rotate(0deg);

        &.off {
          transform: rotate(180deg); } } }

    &__title {
      font-weight: bold;
      text-align: left;
      font-size: 0.9rem;
      padding: 1.2rem 1.2rem 0.5rem 1.2rem;
      border-bottom: none;
      margin-bottom: 0;
      @include rwd(1088) {
        border-bottom: 1px solid  $cp7; } }
    &__link {
      font-size: 0.8rem;
      text-align: left;
      padding-left: 1.1rem;
      font-weight: 600;
      color: $cg2;
      @include rwd(1088) {
 }        // color: $cp5
      &:hover {
        color: $cp1;
        background: none; } }
    &__icon {
      color: $cp1;
      font-weight: bold; }

    &__button {
      min-width: 180px;
      max-width: 200px;
      border-radius: 50px;
      font-weight: bold;
 }      // height: 58px
    &.login-desktop {
      display: none;
      @include rwd(1088) {
        display: block;
        padding-right: 20px !important; } }
    &.login-mobile {
      display: block;
      @include rwd(1088) {
        display: none; }
      .box-login {
        &__content {
          display: block;
          width: 100%;
          border-bottom: 1px solid  $cg2;
          padding-bottom: 10px;
          margin-bottom: 10px; }
        &__menu {
          display: block;
          width: 100%;

          .dropdown-menu {
            display: block;
            width: 100%;
            padding-left: 70px;
            @include sm {
              padding-left: 110px; } } }

        &__link {
          font-weight: 300;
          padding-bottom: 0; }
        &__button {
          margin: 20px auto 20px; } }



      .dropdown-trigger {
        display: block;
        position: absolute;
        top: 21%;
        width: 19%;

        .icon {
          display: none; } } } } }

//- .............................. { Search Mobile }
.search-mobile {
  position: absolute;
  right: 70px;
  top: 18px;
  display: block;
  @include rwd(1088) {
    display: none; }
  &__link {
    color: $cp1;
    font-size: 1.5rem; } }


//- .............................. { Reescribiendo nav-bar version mobile }
.navbar-start {
  @media (max-width: 1199px) {
    flex-direction: column !important; }
 }  // @include sm
.m--avatar {
  padding: 2px 11px; }

.active {
  #home-icon {
    fill: $cp1; } }

.home-icon {
  transition: ease all .2s;
  &:hover {
    fill: $cp1; } }

.locatarios {
  margin-top: 20px;
  max-width: 350px !important;
  max-height: 40px;
  padding: 12px 20px;
  background: #f0f0f0;

  @include rwd(1440) {
    margin-top: 0;
    max-width: 170px !important;
    max-height: 30px; }

  &:hover {
    background: $cp1;
    color: $cb; } }
