// global
.slick-slider {
  padding-top: 40px;
  margin: 0;
  margin-bottom: 60px;
  @include sm {
    padding: 0; }
  .slick-arrow {
    z-index: 1;
    background: $cp1;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: all 0.3s ease;
    top: -30px;
    @include sm {
      top: auto;
      bottom: -45px; }

    &::before {
      font-family: 'icomoon';
      content: "\e93c";
      color: $cb; }

    &:hover {
      background: $cp8 !important;
      &::before {
        color: $cb !important; } }


    &.slick-next {
      left: 35px;
      transform: rotate(-90deg);
      @include sm {
        right: 0;
        left: auto; } }

    &.slick-prev {
      left: 0px;
      transform: rotate(90deg);
      @include sm {
        right: 35px;
        left: auto; } } }

  .slick-dots {
    bottom: 10px;
    li {
      margin: 0;
      button {
        &::before {
          opacity: 1;
          font-size: 10px;
          color: $cb; } } }
    li.slick-active {
      button {
        &::before {
          color: $cp1; } } } } }

// .......................................................................... { sliders main } #
#cont-slider-main {
  // .img-slider
  //   display: block
  //   width: 100%
  //   height: 250px
  //   @include md
  //     height:  50vh
  //   @include lg
  //     height:  60vh
  //   @include rwd(1400)
  //     height:  80vh
  // .slider-main
  //   position: relative
  //   padding: 0
  //   &__items
  //     background-repeat: no-repeat
  //     background-size: cover
  //     background-position: 40% center
  //     @include sm
  //       background-position: center center
  //   &__img
  //     &--desktop
  //       display: block !important
  //       max-width: 100%
  //     &--mobile
  //       display: none !important
  //       max-width: 100%

  .slider-main {
    margin-bottom: 0px !important;
    padding: 0;
    .slick-arrow {
      background: rgba(255, 255, 255, 0.2);
      width: 35px;
      height: 35px;
      top: calc(50% - 17.5px);
      bottom: auto;
      border-radius: 50%;

      @include sm {
        width: 45px;
        height: 45px;
        top: calc(50% - 22.5px); }

      &.slick-next {
        right: 10px;
        left: auto;
        transform: rotate(-90deg); }
      &.slick-prev {
        right: auto;
        left: 10px;
        transform: rotate(90deg); }
      &:hover {
        background: $cp1; } }
    &__items {
      img {
        // height: 240px
        width: 100%; } } } }

$slider-main-bg: #fff;
$slider-main-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);

.slider-cards {
  margin-bottom: 50px !important;
  margin: 0 auto;
  padding-top: 0;
  max-width: 1200px;
  width: 100%;

  .slick-dots {
    bottom: -30px; }

  .slick-arrow {
    width: 35px;
    height: 35px;
    top: calc(50% - 17.5px);
    bottom: auto;
    border-radius: 50%;
    background: $slider-main-bg;
    -webkit-box-shadow: $slider-main-shadow;
    -moz-box-shadow: $slider-main-shadow;
    box-shadow: $slider-main-shadow;

    @include sm {
      width: 45px;
      height: 45px;
      top: calc(50% - 22.5px); }

    &.slick-next {
      right: 10px;
      left: auto;
      transform: rotate(-90deg);
      @include fullhd {
        right: 20px; }
      @media screen and (min-width: 1400px) {
        right: -60px; } }
    &.slick-prev {
      right: auto;
      left: 10px;
      transform: rotate(90deg);
      @include fullhd {
        left: 20px; }
      @media screen and (min-width: 1400px) {
        left: -60px; } } } }

.slider-img {
  display: none;
  @include rwd(768) {
    display: block; }
  &--mobile {
    display: block;
    @include rwd(768) {
      display: none; } } }



