// NOTE: PQR will be a general module for multiple proyects, so every color, font, structurre, etc. Will be configured here to make easier the copy and paste of this module.
// NOTE: I made a mistake in the html, naming everything with "pqr" at the start. Following BEM standards it should be "pqr-" and "pqr CamelCase"
/*========================================*/
/*VARIABLES*/
/*========================================*/

/*FONTS==========*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600,700,800");
$pqr-font-family: "Roboto";
$pqr-font-size: 15px;

/*COLORS==========*/
$pqr-cp1: #903392;
$pqr-cp2: #005ed1;
$pqr-cp2-light: #f0f6ff;
$pqr-cp3: #5fcc00;
$pqr-cp3-light: #f7fff0;
$pqr-cp4: #292c33;
$pqr-cp5: #b3b3b3;
$pqr-cp6: #f0f0f0;
$pqr-cp7: #eeeff0;
$pqr-cp8: #d2d3d6;
$pqr-cp9: #f8f9fa;
$pqr-cp10: #fff;
$pqr-cp11: #fcfcfc;
$pqr-cp12: #e6e6e6;

/*BORDERS==========*/
$pqr-btn-border-radius: 22px;
$pqr-wrapper-border-radius: 20px;
$pqr-border-weight: 1px;

/*ICONS==========*/
$pqr-icon-font-family: "icomoon";
$pqr-icon-font-family2: "icomoon2";
$pqr-icon-arrow: "\e901";
$pqr-icon-search: "\e90f";
$pqr-icon-upload: "\e901";
$pqr-icon-image: "\e912";
$pqr-icon-play: "\e911";
$pqr-icon-reply: "\e909";
$pqr-icon-attach: "\e910";
$pqr-icon-download: "\e90a";
$pqr-icon-doc: "\e903";
$pqr-icon-add: "\e915";
$pqr-icon-close: "\e927";
$pqr-icon-state-inbox-date: "\e91e";
$pqr-icon-state-due-date: "\e91d";
$pqr-icon-check: "\e938";
/*TRANSITIONS==========*/
$pqr-transition-speed: 0.3s;
$pqr-transition-type: ease-in-out;

/*RESPONSIVE==========*/
$pqr-screen-xs-min: 320px;
$pqr-screen-sm-min: 576px;
$pqr-screen-md-min: 768px;
$pqr-screen-lg-min: 992px;
$pqr-screen-xl-min: 1200px;

/*SPACERS==========*/
$pqr-margin-spacer: 5px;
$pqr-padding-spacer: 5px;

/*========================================*/
/*MEDIA QUERIES*/
/*========================================*/

/*Small devices==========*/
@mixin pqr-xs {
  @media (min-width: #{$pqr-screen-xs-min}) {
    @content;
  }
}

/*Small devices==========*/
@mixin pqr-sm {
  @media (min-width: #{$pqr-screen-sm-min}) {
    @content;
  }
}

/*Medium devices==========*/
@mixin pqr-md {
  @media (min-width: #{$pqr-screen-md-min}) {
    @content;
  }
}

/*Large devices==========*/
@mixin pqr-lg {
  @media (min-width: #{$pqr-screen-lg-min}) {
    @content;
  }
}

/* Extra large devices==========*/
@mixin pqr-xl {
  @media (min-width: #{$pqr-screen-xl-min}) {
    @content;
  }
}

/* Landscape==========*/
@mixin pqr-landscape {
  @media screen and (min-width: #{$pqr-screen-xs-min}) and (max-width: 767px) and (orientation: landscape) {
    @content;
  }
}

/* Custom devices==========*/
@mixin pqr-rwd($pqr-screen) {
  @media (min-width: $pqr-screen+"px") {
    @content;
  }
}

/*========================================*/
/*UTILITIES*/
/*========================================*/

/* Margins==========*/
@for $i from 0 through 5 {
  .pqr_m-#{$i} {
    margin: calc(#{$pqr-margin-spacer} * #{$i}) !important;
  }
}
@for $i from 0 through 5 {
  .pqr_mt-#{$i} {
    margin-top: calc(#{$pqr-margin-spacer} * #{$i}) !important;
  }
}
@for $i from 0 through 5 {
  .pqr_mb-#{$i} {
    margin-bottom: calc(#{$pqr-margin-spacer} * #{$i}) !important;
  }
}
@for $i from 0 through 5 {
  .pqr_ml-#{$i} {
    margin-left: calc(#{$pqr-margin-spacer} * #{$i}) !important;
  }
}
@for $i from 0 through 5 {
  .pqr_mr-#{$i} {
    margin-right: calc(#{$pqr-margin-spacer} * #{$i}) !important;
  }
}

/* Paddings==========*/
@for $i from 0 through 5 {
  .pqr_p-#{$i} {
    padding: calc(#{$pqr-padding-spacer} * #{$i}) !important;
  }
}
@for $i from 0 through 5 {
  .pqr_pt-#{$i} {
    padding-top: calc(#{$pqr-padding-spacer} * #{$i}) !important;
  }
}
@for $i from 0 through 5 {
  .pqr_pb-#{$i} {
    padding-bottom: calc(#{$pqr-padding-spacer} * #{$i}) !important;
  }
}
@for $i from 0 through 5 {
  .pqr_pl-#{$i} {
    padding-left: calc(#{$pqr-padding-spacer} * #{$i}) !important;
  }
}
@for $i from 0 through 5 {
  .pqr_pr-#{$i} {
    padding-right: calc(#{$pqr-padding-spacer} * #{$i}) !important;
  }
}

/* Grids==========*/
@for $i from 1 through 5 {
  .pqr_col-md-span-#{$i} {
    grid-column: span 1;
    @include pqr-md() {
      grid-column: span #{$i};
    }
  }
}
@for $i from 1 through 5 {
  .pqr_row-md-span-#{$i} {
    grid-column: span 1;
    @include pqr-md() {
      grid-column: span #{$i};
    }
  }
}

/*========================================*/
/*GLOBAL*/
/*========================================*/
.pqr {
  font-family: $pqr-font-family;
  font-size: $pqr-font-size;
  padding: 15px;
  padding-bottom: 40px;
  // background: $pqr-cp11;

  &_container {
    width: 100%;
    max-width: 100%;
    margin: auto;
    display: block;
    @include pqr-sm() {
      max-width: 540px;
    }
    @include pqr-md() {
      max-width: 720px;
    }
    @include pqr-lg() {
      max-width: 960px;
    }
    @include pqr-xl() {
      max-width: 1140px;
    }
  }

  &_wrapper {
    display: grid;
    grid-auto-flow: row;

    &--bg {
      &-white {
        border-radius: $pqr-wrapper-border-radius;
        background: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        color: $pqr-cp4;
      }
      &-cp3 {
        background: $pqr-cp3;
        color: white;
      }
    }
  }

  &_resp {
    display: inherit !important;
    @include pqr-md() {
      display: none !important;
    }
  }

  &_desk {
    display: none !important;
    @include pqr-md() {
      display: block !important;
    }
  }

  &_desk-flex {
    display: none !important;
    @include pqr-md() {
      display: flex !important;
    }
  }

  &_desk-grid {
    display: none !important;
    @include pqr-md() {
      display: flex !important;
    }
  }

  &_justify {
    &-content {
      &-center {
        justify-content: center;
      }

      &-start {
        justify-content: start;
      }

      &-end {
        justify-content: end;
      }

      &-spaceBet {
        justify-content: space-between;
      }

      &-spaceAro {
        justify-content: space-around;
      }
    }
  }

  .pqr_h1,
  h1 {
    font-family: $pqr-font-family;
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;
  }

  .pqr_h2,
  h2 {
    font-family: $pqr-font-family;
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;
  }

  .pqr_h3,
  h3 {
    font-family: $pqr-font-family;
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;
  }

  .pqr_h4,
  h4 {
    font-family: $pqr-font-family;
    font-size: 23px;
    font-weight: normal;
    letter-spacing: 0.03em;
    line-height: 27.6px;
    color: $pqr-cp4;
  }

  .pqr_h5,
  h5 {
    font-family: $pqr-font-family;
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;
  }

  .pqr_h6,
  h6 {
    font-family: $pqr-font-family;
    font-size: 15px;
    line-height: 25.78px;
    font-weight: bold;
    color: $pqr-cp5;
  }

  p {
    font-family: $pqr-font-family;
    font-size: $pqr-font-size;
    color: $pqr-cp4;
  }

  hr.pqr_hr {
    background-color: $pqr-cp7;
    border: none;
    display: block;
    height: 2px;
    margin: 20px 0;
  }
}
/*========================================*/
/*ICONS*/
/*========================================*/
.pqr_icon {
  position: relative;
  transition: all $pqr-transition-speed $pqr-transition-type;

  &::after {
    font-family: $pqr-icon-font-family;
    line-height: 1;
    color: inherit;
    position: absolute;
    transition: all $pqr-transition-speed $pqr-transition-type;
  }

  &-arrow {
    &::after {
      content: $pqr-icon-arrow;
      transform: rotate(180deg);
    }
  }

  &-arrowleft {
    &::after {
      content: $pqr-icon-arrow;
      transform: rotate(0deg);
    }
  }

  &-arrowright {
    &::after {
      content: $pqr-icon-arrow;
      transform: rotate(-90deg);
    }
  }

  &-arrowup {
    &::after {
      content: $pqr-icon-arrow;
      transform: rotate(180deg);
    }
  }

  &-search {
    &::after {
      content: $pqr-icon-search;
    }
  }

  &-upload {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: "\e910";
    }
  }

  &-image {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-image;
    }
  }

  &-play {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-play;
    }
  }

  &-reply {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-reply;
    }
  }

  &-attach {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-attach;
    }
  }

  &-download {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-download;
    }
  }

  &-doc {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-doc;
    }
  }

  &-add {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-add;
    }
  }
  &-close {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-close;
    }
  }

  &-state-inbox-date {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-state-inbox-date;
    }
  }

  &-state-due-date {
    &::after {
      // NOTE: yeah there are two icomoon fonts .__.
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-state-due-date;
    }
  }
}
/*========================================*/
/*BUTTONS*/
/*========================================*/

.pqr_btn {
  border-radius: $pqr-btn-border-radius;
  border: $pqr-border-weight solid transparent;
  transition: all $pqr-transition-speed $pqr-transition-type;
  width: 100%;
  padding: 0 1rem;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: $pqr-font-family;
  font-size: $pqr-font-size;
  font-weight: bold;
  margin: auto;
  cursor: pointer;

  &--bg {
    &-primary {
      background: $pqr-cp1;
      color: white;

      &:focus,
      &:hover {
        filter: brightness(1.1);
      }
    }

    &-cp4 {
      background: $pqr-cp1;
      color: white;

      &:focus,
      &:hover {
        background: $pqr-cp1;
        color: $white;
      }
    }

    &-cp9 {
      background: $pqr-cp5;
      color: #fff;
      border-color: $pqr-cp8;

      &:focus,
      &:hover {
        background: $pqr-cp1;
        color: white;
      }
    }

    &-white {
      background: white;
      color: $pqr-cp4;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04));

      &:focus,
      &:hover {
        color: $pqr-cp1;
        border-color: $pqr-cp1;
      }

      &-cp3 {
        background: white;
        color: $pqr-cp3;

        &:focus,
        &:hover {
          filter: brightness(1.1);
        }
      }
    }

    &-transparent {
      background: transparent;
      color: $pqr-cp4;
      border-color: $pqr-cp4;

      &:focus,
      &:hover {
        color: $pqr-cp1;
        border-color: $pqr-cp1;
      }
    }
  }

  &--font {
    &-small {
      font-size: 13px;
    }
  }

  &--size {
    &-bigger {
      padding: 0 2.5rem;
    }
  }

  &-goBack {
    width: 100%;
    max-width: 335px;
    @include pqr-md() {
      max-width: initial;
      width: max-content;
    }

    .pqr_icon {
      padding-left: 24px;
      display: flex;
      align-items: center;

      &::after {
        left: 0;
      }
    }
  }

  &-toggleContent {
    background: $pqr-cp7;
    color: $pqr-cp4;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-items: center;

    &--state {
      &-active {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    &:hover,
    &:focus {
      color: $pqr-cp1;
      border-color: $pqr-cp1;
      background: white;
    }
  }

  &-reply {
    background: transparent;
    width: 16px;
    height: 16px;
    padding: 0;
  }

  &-attach {
    background: transparent;
    width: 16px !important;
    height: 16px;
    display: flex !important;
    padding: 0;
  }

  &-crearPQR {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    @include md() {
      width: max-content;
      border-radius: $pqr-btn-border-radius;
      &::after {
        content: "";
      }
    }
  }

  &-download {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    background: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04));
    &:hover,
    &:focus {
      color: $pqr-cp1;
    }
  }

  &-close {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    background: $pqr-cp1;
    color: white;

    &:hover,
    &:focus {
      filter: brightness(1.1);
    }
  }
}
/*========================================*/
/*FORMS*/
/*========================================*/

.pqr_form {
  &__group {
    padding-bottom: 20px;
  }

  &__label {
    display: block;
    text-align: left;
    font-size: 15px;
    letter-spacing: 0.03em;
    line-height: 18px;
    color: $pqr-cp4;
    font-weight: bold;
    padding-left: 1rem;
    margin-bottom: 5px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    @include pqr-md() {
      justify-content: end;
    }
    @include pqr-lg() {
      margin-bottom: 30px;
    }

    button {
      width: 50%;
      margin: 0 15px;
      @include pqr-md() {
        width: auto;
      }
    }
  }

  &__alert {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0.03em;
    line-height: 18px;
    padding-left: 1rem;

    &--type {
      &-error {
        color: red;
        width: 100%;
      }
    }
  }
}

.pqr_wrapper-input {
  display: flex;
  align-items: center;

  &::after {
    right: 15px;
    font-size: 20px;
  }
}

.pqr_input,
.pqr_textarea {
  border-radius: $pqr-btn-border-radius;
  border: $pqr-border-weight solid $pqr-cp8;
  transition: all $pqr-transition-speed $pqr-transition-type;
  width: 100%;
  padding: 0.6rem 1rem;
  font-family: $pqr-font-family;
  font-size: $pqr-font-size;
  line-height: 1.5;
  font-weight: normal;
  background: white;
  // color: $cp1;

  &::placeholder {
    color: $pqr-cp5;
  }
}

.pqr_input {
  &-message {
    padding-right: 110px;
  }

  &-search {
    padding-right: 40px;
  }
}

.pqr_textarea {
  min-height: 150px;
}

.pqr_checkboxes {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
  margin: -7.5px; // NOTE: To simulate a grid-gap cuz it doesnt has a lot of compatibility across browsers with flex
}

.pqr_checkbox {
  margin: 7.5px; // NOTE: To simulate a grid-gap cuz it doesnt has a lot of compatibility across browsers with flex
  &__input {
    display: none;
    &:checked + label::before {
      font-family: $pqr-icon-font-family;
      content: $pqr-icon-check;
      background: $pqr-cp1;
      border-color: $pqr-cp1;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover,
    &:focus {
      &::before {
        border-color: $pqr-cp1;
      }
    }
    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $pqr-icon-font-family2;
      font-size: 12px;
      color: white;
      content: "";
      width: 20px;
      height: 20px;
      transition: all $pqr-transition-speed $pqr-transition-type;
      margin-right: 16px;
      border-radius: 4px;
      border: 1px solid $pqr-cp8;
    }
  }
}

.pqr_select {
  position: relative;
  padding: 0;

  &:focus,
  &:hover {
    box-shadow: none;
  }

  select {
    display: none;
  }
  /* Style items (options): */
  &__items-wrapper {
    padding: 1rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background: white;
    border-radius: 1.25rem;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.1);

    &--hidden {
      display: none;
    }
  }
  /* style the items (options), including the selected item: */
  &__item,
  &__select {
    cursor: pointer;
    margin: 0.1rem 0;
  }

  &__select {
    border-radius: $pqr-btn-border-radius;
    border: $pqr-border-weight solid $pqr-cp8;
    transition: all $pqr-transition-speed $pqr-transition-type;
    width: 100%;
    padding: 0.6rem 1rem;
    font-family: $pqr-font-family;
    font-size: $pqr-font-size;
    font-weight: normal;
    background: white;
    // color: $cp1;
    line-height: 1.5;
    display: flex;
    align-items: center;

    &.select-active:after {
      transform: rotate(-90deg);
    }
    /* Style the arrow inside the select element: */
    &:after {
      right: 15px;
    }
  }

  &__item {
    padding: 0.1em 0.5em;
    position: static;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
    display: block;

    &:focus,
    &:hover {
      background-color: rgba($cp1, 0.1);
      color: $cp1;
      border-radius: 1em;
    }
  }
}

.pqr_preloader {
  width: 20px;
  height: 20px;
  border: 3px solid $pqr-cp7;
  border-top: 3px solid $pqr-cp1;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// NOTE: Code from extranet
// Multi Upload

#drop-area {
  display: block;
  width: 100%;
  transition: all $pqr-transition-speed $pqr-transition-type;
  cursor: pointer;
  &.highlight {
    border-color: $pqr-cp1;
  }
}

#gallery {
  display: grid;
  align-items: center;
  grid-gap: 15px;
  i,
  img,
  span {
    vertical-align: middle;
  }

  i {
    font-size: 2rem;
    margin-right: 10px;
  }

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    margin-right: 10px;
  }

  div {
    display: block;
    position: relative;
    width: auto;

    &:nth-child(3n) {
      margin-right: 0 !important;
    }
  }

  .delete-file {
    margin-left: 10px;

    &:after {
      font-family: $pqr-icon-font-family;
      content: "\e927";
      display: flex;
      justify-content: center;
    }
  }
}

.delete-file,
.delete-thumb {
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
  color: $pqr-cp1 !important;
  padding: 2px;
  font-size: 18px;
  display: inline-block;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

// NOTE: The icon "delete" is from the extranet scripts so i can't handle from here the icon's content.
.pqr_dropzone {
  // background: $pqr-cp10;
  border-radius: 22px;
  padding: 20px;
  font-size: 15px;

  &__dropArea#drop-area {
    display: flex;
    background: #eeeeee;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed $pqr-cp1;
    min-height: 160px;
    border-radius: 22px;
  }

  &__title {
    color: $pqr-cp4;
    font-weight: bold;
    margin-bottom: 15px;

    span.pqr_icon {
      padding-left: 25px;
      &::after {
        left: 0;
      }
    }
  }

  &__label {
    color: $pqr-cp5;
    width: 100%;
    max-width: 405px;
  }

  &__gallery#gallery {
    margin: 0;

    & > * {
      margin-top: 10px;
    }
  }
}

/*========================================*/
/*LABELS*/
/*========================================*/
.pqr_label {
  border-radius: $pqr-btn-border-radius;
  border: $pqr-border-weight solid transparent;
  width: max-content;
  padding: 0 1rem;
  height: 27px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: $pqr-font-family;
  font-size: 13px !important;
  font-weight: bold;
  cursor: default;

  &--state {
    &-progress {
      background: $pqr-cp2-light !important;
      color: $pqr-cp2 !important;
    }

    &-success {
      background: $pqr-cp3-light !important;
      color: $pqr-cp3 !important;
    }
  }
}

/*========================================*/
/*MODALS*/
/*========================================*/
.pqr_modal {
  display: none;
  &.pqr_modal--show {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(white, 0.9);
    z-index: 700;
  }

  &__container {
    width: 100%;
    max-height: 90%;
    overflow-y: scroll;
    // NOTE: Use this media query to not overwrite the max-width property of the class "container" in md lg xl devices
    @media (max-width: 576px) {
      max-width: 335px;
    }
  }
  &__close {
  }

  &__header {
  }

  &__content {
  }

  &__footer {
  }
}
.pqr_modal-files {
  .pqr_modal__header {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto max-content max-content;
    align-items: center;
    margin-bottom: 25px;
    @include pqr-md() {
      margin-bottom: 20px;
    }
  }
  .pqr_modal__file {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-details {
      display: flex;
      flex-flow: column;
      line-height: 16px;
    }
    &-name {
      color: $pqr-cp4;
    }
    &-size {
      color: gray;
    }
    &-actions {
    }
  }
}

/*========================================*/
/*pqr-login.html*/
/*========================================*/

#pqr_login {
  @include pqr-md() {
    padding: 70px;
  }
  @include pqr-xl() {
    padding: 130px 70px;
  }
}
/*========================================*/
/*pqr-start.html*/
/*========================================*/

#pqr_start {
  .pqr_wrapper#pqr_wrapper_start {
    max-width: 280px;
    text-align: center;
    margin: auto;

    figure {
      background: $pqr-cp7;
      width: 112px;
      height: 112px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }

    p {
      margin: 50px 0;
    }
  }
}
/*========================================*/
/*pqr-home.html*/
/*========================================*/

#pqr_home {
  .pqr_wrapper#pqr_home__wrapper {
  }
}

.pqr_nav {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  margin-bottom: 34px;
  @include pqr-md() {
    grid-auto-flow: columns;
    grid-template-columns: 1fr 1fr;
  }

  &__search {
    max-width: 280px;
    width: 100%;
    margin: auto;
    @include pqr-md() {
      margin-right: 0;
    }
  }

  &__status {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-auto-flow: column;
    align-items: center;
    grid-gap: 10px;
    justify-content: left;
    overflow-y: scroll;
    @include pqr-md() {
      grid-template-columns: repeat(4, 1fr);
      order: -1;
      overflow-y: initial;
    }
  }
}

.pqr_tickets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-gap: 15px;
  justify-items: center;
  align-items: center;
  @include pqr-md() {
    justify-items: start;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    @include pqr-md() {
      margin: 50px 0;
    }
  }
}

.pqr_ticket {
  width: 100%;
  max-width: 265px;
  min-height: 260px;
  padding: 24px;
  display: grid;
  grid-gap: 24px;

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 17px;
    grid-column: span 2;
  }

  &__date {
    grid-column: span 2;

    span {
      color: $pqr-cp5;
    }
  }

  &__info {
    grid-column: span 1;
  }

  span {
    width: 100%;
    display: block;
  }
}
/*========================================*/
/*pqr-crear-ticket.html*/
/*========================================*/

#pqr_crear-ticket {
  #pqr_crear-ticket__form {
    h6 {
      margin-bottom: 40px;
    }

    #pqr_crear-ticket__inputs {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
      padding: 20px;
      @include pqr-md() {
        grid-template-columns: 1fr 1fr;
        padding: 30px;
        padding-bottom: 90px;
      }
    }
  }
}

.pqr_header {
  display: grid;
  align-items: center;
  grid-template-areas:
    "title     actions"
    "divisor   divisor"
    "goBack    goBack"
    "alert     alert";
  grid-gap: 15px;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    margin-top: 50px;
  }

  @media (max-width: 992px) {
    margin-top: 0;
  }

  @include pqr-md() {
    grid-template-areas:
      "goBack    title     actions"
      "divisor   divisor   divisor"
      "alert     alert     alert";

    grid-template-columns: min-content auto auto; // NOTE:To not divide the grid in equal parts

  }

  &__title {
    grid-area: title;
  }
  &__actions {
    grid-area: actions;
    display: grid;
    justify-content: end;
  }

  &__divisor {
    grid-area: divisor;
    hr {
      margin: 0 !important; // NOTE: To use grid gap instead
      width: 100vw;
      position: absolute;
      left: 0;
      // NOTE: DON'T SET TOP:0. There is no that property cuz the position of the <hr> must be where its parent is.
    }
  }
  &__goBack {
    grid-area: goBack;
  }
  &__alerts {
    grid-area: alert;
  }

  &__alert {
    display: grid;
    padding: 20px;
    grid-gap: 20px;
    border-radius: 22px;
    @include pqr-md() {
      padding: 25px;
      grid-auto-flow: column;
      align-items: center;
      justify-content: space-between;
    }
    a.pqr_btn {
      width: 100%;
      max-width: 335px;
      @include pqr-md() {
        width: max-content;
        max-width: auto;
      }
    }
    // NOTE: IF it's the green one, set color white
    &.pqr_wrapper--bg-cp3 {
      p {
        color: white;
      }
    }
  }
}

/*========================================*/
/*pqr-ticket-exito.html*/
/*========================================*/

#pqr_ticket_exito {
  #pqr_ticket_exito__wrapper {
    #pqr_ticket_exito__message {
      padding: 75px 0;
      width: 100%;
      max-width: 295px;
      text-align: center;
      display: block;
      margin: auto;
      @include pqr-md() {
        max-width: 335px;
        padding: 145px 0;
      }

      figure {
        border-radius: 50%;
        background: $pqr-cp7;
        width: 97px;
        height: 97px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto auto 64px;
        @include pqr-md() {
          width: 120px;
          height: 120px;
        }
      }

      p {
        margin-bottom: 34px;
      }

      button {
        margin-top: 64px;
        width: 100%;
      }
    }
  }
}
/*========================================*/
/*pqr-chat.html*/
/*========================================*/

#pqr_chat {
  #pqr_chat__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;
    @include pqr-md() {
      grid-template-columns: 1fr 1fr;
    }
    @include pqr-lg() {
      grid-template-columns: 1fr 2fr;
    }
  }
}

.pqr_chat-file {
  background: $pqr-cp6;
  padding: 10px 42px;
  border-radius: 4px;
  display: flex;
  margin: 7.5px; // NOTE: To simulate a grid-gap cuz it doesnt has a lot of compatibility across browsers with flex

  .pqr_icon {
    &:after {
      font-size: 16px;
    }

    &-doc,
    &-image,
    &-play {
      &:after {
        left: -30px;
      }
    }

    &-download {
      display: grid;
      align-items: center;

      &:after {
        right: -30px;
      }
    }
  }

  &__audio {
    display: flex;

    &-player {
      display: grid;
    }

    &-timebar {
      position: relative;
      background-color: $pqr-cp4;
      margin: 7px 0 5px;
      height: 1px;
      min-width: 175px;
      grid-column: span 2;
    }

    &-currentTime,
    &-time {
      font-size: 12px;
    }

    &-currentTime {
      justify-self: start;
    }

    &-time {
      justify-self: end;
    }

    &-timeline {
      background-color: $uniGralText;
      position: absolute;
      top: -4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #8d918e;
      display: block;
      cursor: pointer;
      box-shadow: 0 0 0 2px rgb(214, 214, 214);
    }

    &-play {
      cursor: pointer;

      &::after {
        top: 7.5px;
      }
    }
  }

  &__name,
  &__size {
    font-size: 12px;
    line-height: 16px;
  }

  &__name {
    color: $pqr-cp4;
  }

  &__size {
    color: gray;
  }

  &__launch-modal,
  &__doc {
    display: grid;
    align-items: center;
  }
}

.pqr_chat-info {
  padding: 20px;
  @include pqr-md() {
    padding: 34px;
  }

  &__title {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;

    h4 {
      font-weight: normal;
      font-size: 17px;
      line-height: 20.4px;
      text-align: left;
      color: $pqr-cp4;
    }
  }

  &__description {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 17px;
    grid-gap: 17px;
    @include pqr-md() {
      display: grid !important; // NOTE: To show the content no matter the resolution, even if the user hide it with the toggle button
    }
  }

  &__date {
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    padding-left: 32px;
    &::after {
      font-size: 22px;
    }

    * {
      font-weight: bold;
    }
  }

  &__category {
    display: grid;
    grid-auto-flow: row;

    *:nth-child(2) {
      font-weight: bold;
    }
  }

  &__detail {
    grid-column: span 2;
  }

  &__files {
    grid-column: span 2;
    display: flex;
    flex-wrap: wrap;
    margin: -7.5px; // NOTE: To simulate a grid-gap cuz it doesnt has a lot of compatibility across browsers with flex
  }

  hr {
    grid-column: span 2;
  }

  &__responsable {
    grid-column: span 2;

    figure {
      margin-top: 15px;
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        width: 34px;
        height: 34px;
        margin-right: 15px;
      }

      figcaption {
        span {
          display: block;

          &:nth-child(1) {
            font-size: 16px;
            font-weight: bold;
          }

          &:nth-child(2) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.pqr_chatbox {
  background: $pqr-cp7;
  padding: 20px;
  display: grid;
  align-content: start;
  grid-gap: 20px 0;
  @include pqr-md() {
    padding: 25px;
  }

  &__messages {
    display: grid;
    grid-gap: 15px 0;
    padding: 0 10px;
    position: relative;
    height: max-content;
    max-height: 600px;
    overflow-y: scroll;
    align-self: start;
  }

  &__date {
    background: $pqr-cp8;
    color: gray;
    font-weight: bold;
    font-size: 12px;
    height: 20px;
    width: max-content;
    padding: 0 15px;
    border-radius: 22px;
    justify-self: center;
  }

  &__message {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 15px;
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      content: "";
    }

    //
    &--who {
      &-them {
        border-radius: 4px 0 4px 4px;
        background: $pqr-cp1;
        color: white;
        justify-self: end;

        &::after {
          right: 0;
          border-width: 10px 10px 0 0;
          border-color: $pqr-cp1 transparent transparent transparent;
          transform: translateX(100%);
        }
      }

      &-me {
        border-radius: 0 4px 4px 4px;
        background: white;
        justify-self: start;

        &::after {
          left: 0;
          border-width: 0 10px 10px 0;
          border-color: transparent white transparent transparent;
          transform: translateX(-100%);
        }

        .pqr_chatbox__message-hour {
          color: gray;
        }
      }
    }

    &--state {
      &-inactive {
        cursor: default;
        pointer-events: none;
        background: $pqr-cp12;
        .pqr_chatbox__message-name,
        .pqr_chatbox__message-hour,
        .pqr_chatbox__message-detail {
          color: $pqr-cp5;
        }
        &.pqr_chatbox__message--who-me::after {
          border-color: $pqr-cp12 transparent transparent transparent;
        }
        &.pqr_chatbox__message--who-them::after {
          border-color: transparent $pqr-cp12 transparent transparent;
        }
      }
    }

    &-name {
      font-size: 14px;
      font-weight: bold;
    }

    &-hour {
      font-size: 12px;
      font-weight: bold;
      justify-self: end;
    }

    &-detail {
      font-size: 14px;
      grid-column: span 2;
    }

    &-files {
      display: flex;
      flex-wrap: wrap;
      grid-column: span 2;
      margin: -7.5px; // NOTE: To simulate a grid-gap cuz it doesnt has a lot of compatibility across browsers with flex
    }
  }

  &__replyBox {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &--state {
      &-inactive {
        cursor: default;
        pointer-events: none;
        input {
          background: $pqr-cp12;
        }
        input {
          border-color: $pqr-cp12;
        }
        button,
        button::after {
          color: $pqr-cp5;
        }
      }
    }
  }

  &__form {
    display: grid;
    align-items: center;
    grid-gap: 15px;
    width: 100%;
    position: relative;

    .pqr_btn-attach,
    .pqr_btn-reply,
    .pqr_preloader {
      position: absolute;
      margin: unset;
    }

    .pqr_btn-reply {
      right: 20px;
    }

    .pqr_btn-attach {
      right: 50px;
    }

    .pqr_preloader {
      right: 80px;
    }
  }
}

.audio-container .icon-icon-image-copia,
.video-container .icon-icon-image-copia {
  color: #000;
  margin-top: 8px;
}

#SendMessage .columns .flex--column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
