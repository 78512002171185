$evento-card-box-shadow: 1px 1px 10px 0px rgba(179,179,179,0.2);
$evento-card-primary: #B2BD00;
$evento-card-hover: #cad600;
$evento-card-alternative: #FAFAFA;
$evento-card-bg: #fff;
$evento-font-color-secondary: #B3B3B3;
$evento-font-color-primary: #333333;


$slider-main-green: #B2BD00;

.evento-card {
    background: $evento-card-bg;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: $evento-card-box-shadow;
    -moz-box-shadow: $evento-card-box-shadow;
    box-shadow: $evento-card-box-shadow;
    -webkit-appearance: none;
    position: relative;
    transition: ease all .3s;
    max-width: 250px;
    display: block;
    height: 470px;

    @include desktop {
        max-width: 345px;
        margin: 0 auto;
        height: 560px; }

    &:hover {
        transform: scale(1.02); }

    &__img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%; }

    &__date {
        display: flex;
        padding: 0 20px;

        &__day {
            font-weight: bold;
            padding-right: 5px; }
        &__month {
            color: $evento-font-color-secondary; } }

    &__title {
        padding: 10px 20px;
        font-size: .9rem;
        font-weight: bold;
        color: $evento-card-primary;
        transition: ease all .3s;
        &:hover {
            color: $evento-card-hover; } }

    &__paragraph {
        font-size: .8rem;
        padding: 0 20px;
        padding-bottom: 40px;
        color: $evento-font-color-primary; } }

.slider-cards--green {
    .slick-arrow {
        &::before {
            color: $slider-main-green; }
        &:hover {
            background: $slider-main-green !important;
            &::before {}
            color: $slider-main-bg !important; } }
    .slick-dots {
        bottom: -25px;
        li {
            button {
                &::before {
                    color: $evento-card-alternative; } } }
        li.slick-active {
            button {
                &::before {
                    color: $slider-main-green; } } } } }

.slider-header-home {
    padding: 0 30px;

    @include desktop {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px; }

    &--green {
        color: $slider-main-green; }

    &__title {
        font-size: 1.6rem; }

    &__button {

        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        justify-items: right;
        margin: 30px 0;
        max-width: 315px;

        &--green {
            background: $evento-card-alternative !important;
            color: $slider-main-green !important;
            &:hover {
                color: $evento-card-hover !important; } }

        @include desktop {
            margin: 0; }



        b {
            font-weight: bold;
            transform: rotate(180deg); }
        h4 {
            font-weight: bold; } } }
