.modal#login-modal {
  .modal-content {
    overflow-x: hidden;
    overflow-y: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    @include md {
      padding-top: 35px; }

    .container {
      // width: 90%
      @include md {
        width: 100%; } }
    form {
      // max-height: calc(100vh - 150px)
      height: 610px;
      max-width: 300px;
      margin: 0 auto;
      overflow-y: auto;
      @include lg {
        display: flex;
        flex-direction: column;
        justify-content: center; } }
    form#form-registro {
      height: 570px;
      justify-content: flex-start;
      padding-top: 30px;
      padding-bottom: 30px; } } }

.modal#first-login-modal {
  .modal-content {
    overflow: hidden;
    form {
      box-shadow: 0 0 1px #666C80;
      padding: 10px 10px 20px;
      background: #fff;
      color: #000;
      border-radius: 16px;
      padding: 1.5rem; }


    h6 {
      text-align: center;
      margin-bottom: 2rem; }

    .button__prmimary {
      margin-top: 2rem; } } }
