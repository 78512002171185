.modal-cookies {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999999;
	background-color: rgba(10,10,10,.6);
	display: none;

	&.modal-cookies-active {
		display: block; }

	&-dialog {
		display: flex;
		width: 100%;
		height: 100%;
		align-items: flex-end;
		justify-content: center;
		padding: 30px 16px; }

	&-content {
		background: $cb;
		border-radius: 10px;
		overflow: hidden;
		padding: 30px;
		width: 100%;
		max-width: 900px;
		margin: 0 auto;

		@media screen and (max-width: 700px) {
			max-width: 550px;
			padding: 20px; } }

	&-wrapper {
		display: grid;
		grid-template-columns: 1fr 200px;
		column-gap: 30px;
		align-items: center;

		@media screen and (max-width: 700px) {
			grid-template-columns: 1fr;
			row-gap: 25px; } }

	&-text {
		p {
			color: $cg1;
			font-weight: 500;

			@media screen and (max-width: 700px) {
				text-align: center; }

			@media screen and (max-width: 570px) {
				font-size: 14px; }

			a {
				color: $cp1 !important;
				text-decoration: underline; } } }

	&-buttons {
		button {
			display: block;
			width: 100%;
			outline: none;
			border: none;
			color: $cb;
			background: $cg7;
			padding: 10px 5px;
			text-align: center;
			border-radius: 20px;
			cursor: pointer;
			font-weight: 800;

			&:active {
				transform: scale(.99); }

			&.cookie-btn-primary {
				background: $cp1;

				&:hover {
					background: rgba($cp1, .92); } } } } }
