// .......................................................................... { sharing_box } #

.sharing-box {
  background-color: white;
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  @include md {
    height: 40px; }
  h3 {
    width: auto;
    font-weight: 700;
    letter-spacing: 0.06rem;
    font-size: 0.9rem;
    margin-right: 0.5em; }
  .social-link {
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    transition: all 0.5s ease;
    font-size: 0.9rem;
    &--face {
      color: #fff;
      background-color: $facebook;
      border-radius: 0.8rem;
      &:hover {
       background-color: rgba($facebook, 0.7); } }
    &--tweet {
      color: #fff;
      background-color: $twitter;
      border-radius: 0.8rem;
      &:hover {
        background-color: rgba($twitter, 0.7); } } }
  ul.social-links {
    float: right;
    .social-link {
      margin-left: 0; }
    li {
      border-right: 1px solid #ececec;
      padding-right: 2rem;
      margin-right: 2rem;
      display: inline-block;
      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: 0; } } } }
