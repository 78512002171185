.new-header {
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1200;
  background: $cb;

  @include rwd(992) {
    height: 145px; }
  @include rwd(1200) {
    height: 115px; }

  &__container {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    height: 100%; }

  &__left {
    margin-right: 20px;
    .logo-header {
      width: 100%;
      max-width: 120px;

      @include rwd(992) {
        max-width: 150px; }

      @include rwd(1440) {
        max-width: 190px; } } }


  &__right {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &__top {
      display: none;
      @include rwd(1440) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50%; } }

    &__bottom {

      @include rwd(1440) {
        height: 50%;
        display: flex; } } }

  &__center {
    display: none;

    @include rwd(992) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap; }

    @include rwd(1440) {
      display: none; }

    .horario {
      &__container {
        @include rwd(992) {
          padding: 0 20px; } } }

    .info__data__box {
      padding-right: 20px; } } }

.followers {
  @include rwd(992) {
    margin: 0;
    border-top: none;
    padding: 0; } }


.new-navbar-header {
  display: none;

  @include rwd(1440) {
    display: flex;
    position: fixed;
    width: 100%;
    max-width: 1200px; } }

.info__data__content {
  @include rwd(992) {
    display: flex; } }

.new-navbar {
  display: block;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $cb;
  z-index: 20;
  padding: 20px;
  overflow: auto;
  padding-bottom: 100px;


  @include rwd(992) {
    height: 50vh;
    top: 110px;
    border-radius: 10px;
    grid-auto-flow: column;
    display: grid;
    grid-template-columns: repeat(4, 190px);
    grid-template-rows: repeat(3, 44px);
    gap: 10px 10px;
    align-content: center;
    justify-content: center;
    justify-items: flex-start; }

  @include rwd(1440) {
    position: initial;
    top: initial;
    left: initial;
    height: 100%;
    display: flex;
    padding-bottom: 0;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    overflow: initial; }

  .horario--mobile {
    @include rwd(992) {
      display: none; } }
  .horario--center {
    @include rwd(1440) {
      display: none; } }

  .followers--mobile {
    @include rwd(992) {
      display: none; } }

  .box__login {
    max-width: 315px;
    margin-bottom: 20px;

    .button {
      @include rwd(1440);
      max-height: 30px; }

    @include rwd(992) {
      grid-column: 4 / 5;
      width: 100%; }
    @include rwd(1440) {
      margin-bottom: 0;
      order: 2;
      max-width: 190px; } }

  .locatarios {

    &--mobile {
      @include rwd(1440) {
        display: none; } }
    @include rwd(992) {
      grid-column: 4 / 5;
      height: 44px;
      margin-top: 0; } }


  .box-login {

    &__avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: relative;
      top: 0;
      left: -12px; }

    &__title {
      font-weight: bold;
      text-align: left;
      font-size: 0.9rem;
      padding: 1.2rem 1.2rem 0.5rem 1.2rem;
      border-bottom: none;
      margin-bottom: 0; }

    &__link {
      font-size: 0.8rem;
      text-align: left;
      padding-left: 1.1rem;
      font-weight: 600;
      color: #B3B3B3; } }


  .navbar-item,
  .navbar-link {
    line-height: 2.5;
    padding-left: 0;

    @include rwd(992) {
      padding-top: 0;
      padding-bottom: 0;
      height: 30px; }
    @include rwd(992) {
      padding-top: 0;
      padding-bottom: initial;
      height: initial; }

    @include rwd(1440) {
      padding-bottom: 0; } }

  .navbar-item__marketplace {
    @include rwd(992) {
      margin-top: 0; }
    @include rwd(1440) {
      max-height: 30px; } }

  .navbar-dropdown {
    .navbar-item {
      padding-left: 10px; }
    .active {
        color: $cb;
        font-weight: 900; } } }

.navbar-dropdown {
  &::before {
    content: "";
    position: absolute;
    top: -40px;
    left: 0;
    width: 90%;
    height: 40px; } }

.navbar-burger {
  @include rwd(1440) {
      display: none; } }

.horario--top,
.info__data__content--top,
.followers--top,
.locatarios--top {
  display: none;
  @include rwd(1440) {
    display: flex; } }
