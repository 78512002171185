$nosotros-font-color-primary: #903392 !default;
$nosotros-font-color-hover: #b841ba !default;
$nosotros-font-color-secondary: #EB7133 !default;
$nosotros-font-color-terteary: #00A092 !default;
$nosotros-font-color-neutral: #fff !default;
$nosotros-footer-bg-color: #fff !default;
$nosotros-footer-font-color: #000 !default;

.nosotros {

	@include desktop {
		a {
			transition: ease all .3s;
			&:hover {
				opacity: 0.8; } } }
	&__title {
		padding: 100px 20px 50px 20px;
		text-align: center;
		font-size: 1.6rem;
		font-weight: 500;
		color: $nosotros-font-color-neutral;

		@include desktop {
			padding: 80px 20px; } }

	&__header {
		color: $nosotros-font-color-neutral;
		font-size: .9rem;
		padding: 0 30px;
		padding-bottom: 30px;
		position: relative;


		p, a {
			color: $nosotros-font-color-neutral; }

		@include tablet {
			display: grid;
			grid-template-columns: 1fr 1fr; }
		@include desktop {
			display: grid;
			grid-template-columns: 1fr 2fr 1fr;
			column-gap: 50px;
			justify-items: flex-start;
			align-items: center;
			width: 100%;
			max-width: 1200px;
			margin: 0 auto; }

		&__logo {
			max-width: 170px;
			padding: 50px 0;
			margin: 0 auto;
			display: block;

			@include desktop {
				padding: 0;
				margin: initial; } }

		&__text {
			padding-bottom: 30px;
			line-height: 1.7rem;

			@include desktop {
				padding: 0; } }

		&__info {
			text-align: left;
			display: flex;
			flex-direction: column;
			font-weight: 800;
			padding-bottom: 30px;

			@include tablet {
				grid-column: 1 / 3;
				align-items: center;
				font-weight: 700; }

			@include desktop {
				grid-column: initial;
				align-items: initial;
				padding: 0; }

			&__address {
				padding-bottom: 30px; }
			&__email {
				padding-bottom: 30px; }
			&__phone {
				padding-bottom: 30px; }
			&__social {
				padding-top: 20px;
				&__box {
					font-size: 1.4rem;
					background: #fff;
					padding: 8px 11px;
					border-radius: 50%;


					@include desktop {
						font-size: 1rem; }

					&:not(last-child) {
						margin-right: 20px;

						@include desktop {
							margin-right: 15px; } }

					b {
						color: $nosotros-font-color-primary; } } } } }
	&__image {
		display: block;
		margin: 16% auto 0 auto;
		width: 100%;
		padding: 0 30px;

		&--img {
			border-radius: 10px; }

		@include desktop {
			max-width: 1200px;
			margin: 100px auto 0 auto;
			position: relative;
			bottom: 50px;
			z-index: 200; } }

	&__content {
		padding: 30px;

		@include desktop {
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding-bottom: 100px; }

		main {
			@include desktop {
				width: 30%;
				padding-right: 30px; } }

		&--vision-container {
			@include desktop {
				padding-left: 30px; } }

		&--mision {
			color: $nosotros-font-color-primary; }
		&--vision {
			color: $nosotros-font-color-primary;

			@include desktop {
				flex-direction: row-reverse; } }

		&__img {
			border-radius: 10px;
			display: block;
			margin: 30px auto 30px auto;
			width: 100%;
			height: 100%;

			@include desktop {
				margin: 0;
				width: 70%; }
			@include fullhd {
				width: 800px; } }
		&__title {
			font-size: 1.6rem;
			padding-bottom: 30px;
			font-weight: 500; }

		&__paragraph--mision {
			color: $nosotros-font-color-primary;
			padding-bottom: 30px;
			line-height: 1.7rem;
			font-size: .9rem; }
		&__paragraph--vision {
			color: $nosotros-font-color-primary;
			padding-bottom: 30px;
			line-height: 1.7rem;
			font-size: .9rem; } }

	&__footer {

		&__background {
			background: $nosotros-footer-bg-color;
			padding: 50px 0;

			@include desktop {
				padding: 80px 0; } }
		&__container {
			padding: 0 30px;

			@include tablet {
				text-align: center; }

			@include desktop {
				display: grid;
				text-align: initial;
				margin-top: -100px;
				grid-template-columns: repeat(2, 1fr);
				max-width: 1200px;
				margin: 0 auto;
				padding-bottom: 100px; }

			&__info {
				display: flex;
				flex-direction: column;

				@include desktop {
					padding: 0; }

				&__title {
					font-size: 1.6rem;
					font-weight: 500;
					padding-bottom: 30px;
					color: $nosotros-font-color-primary; }
				&__button {
					padding-bottom: 30px;
					font-size: .9rem;
					max-width: 315px;
					width: 100%;

					button {

						&:hover {
							background: $nosotros-font-color-hover !important; } }



					@include tablet {
						margin: 0 auto; }
					@include desktop {
						margin: initial; } } }



			&__help {
				padding-bottom: 100px;

				&__title {
					font-size: 1.6rem;
					font-weight: 500;
					padding-bottom: 30px;
					color: $nosotros-font-color-primary; }
				&__container {
					@include desktop {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						align-items: center;
						grid-gap: 0 20px; }
					.nosotros-horario {
						font-size: 0.8rem;
						&__schedule {
							padding-bottom: 20px; }
						&__box {
							padding-bottom: 20px;
							&__bold {
								color: $nosotros-footer-font-color; }
							&__small {
								font-size: 0.6rem; } } } }
				&__button {
					width: 100%;
					max-width: 400px;
					margin: 0 auto;
					display: block;
					margin-bottom: 50px;
					transition: ease all .3s;
					button {

						&:hover {
							background: $nosotros-font-color-hover !important; } }



					@include desktop {
						margin: 0;
						max-width: 250px; } } } } } }


